import { ActionTree, Module } from 'vuex';
import { RepositoryFactory } from '@/api/RepositoryFactory';
import AuthRepository from '@/api/repositories/AuthRepository';
import User from '@/models/User';
import UserRole from '@/models/user-attributes/UserRole';
import { Vue } from 'vue-property-decorator';
import ApiError from '@/api/misc/ApiError';
import {ResetPWBody} from '@/interfaces/Auth';
import UserRepository from '@/api/repositories/UserRepository';

const authRepository: AuthRepository = RepositoryFactory.get('auth');
const userRepository: UserRepository = RepositoryFactory.get('user');

enum authStoreState {
    TOKEN = 'token',
    USER = 'user',
    REFRESH_TOKEN = 'refreshToken',
    IS_REFRESHING_TOKEN = 'isRefreshingToken',
    FAILED_QUEUE = 'failedQueue',
}

const store = {
    [authStoreState.TOKEN]: undefined,
    [authStoreState.USER]: undefined,
    [authStoreState.REFRESH_TOKEN]: undefined,
    [authStoreState.IS_REFRESHING_TOKEN]: false,
    [authStoreState.FAILED_QUEUE]: [],
};

export enum authStoreActions {
    LOGIN_ACTION = 'loginAction',
    VALIDATE_HASH_ACTION = 'validateHashAction',
    SET_PASSWORD_ACTION = 'setPasswordAction',
    RESET_PASSWORD_ACTION = 'resetPasswordAction',
    REFRESH_TOKEN = 'refreshToken',
    TEST_ENVIRONMENT = 'testEnvironment',
}

const actions: ActionTree<any, any> = {
    [authStoreActions.LOGIN_ACTION]: async ({commit}, payload: { username: string, password: string }): Promise<User> => {
        const loginDataRaw = await authRepository.login(payload);
        loginDataRaw.user = User.parseFromObject(loginDataRaw.user);
        commit(authStoreMutations.SAVE_LOGIN, loginDataRaw);
        return loginDataRaw.user;
    },
    [authStoreActions.VALIDATE_HASH_ACTION]: async ({commit}, hash: string): Promise<void> => {
        return authRepository.validateHash(hash);
    },
    [authStoreActions.SET_PASSWORD_ACTION]: async ({commit}, payload: { hash: string, password: string }):
      Promise<User> => {
        const loginDataRaw = await authRepository.setPassword(payload);
        loginDataRaw.user = User.parseFromObject(loginDataRaw.user);
        commit(authStoreMutations.SAVE_LOGIN, loginDataRaw);
        return loginDataRaw.user;
    },
    [authStoreActions.RESET_PASSWORD_ACTION]: async ({commit}, login: ResetPWBody): Promise<void> => {
        await authRepository.resetPassword(login);
    },
    [authStoreActions.REFRESH_TOKEN]: async ({commit}, token: string): Promise<{ token: string, refreshToken: string, user: User }> => {
        return await authRepository.refreshToken(token);
    },
    [authStoreActions.TEST_ENVIRONMENT]: async ({commit}): Promise<User> => {
        const testLoginData = await userRepository.testEnvironment();
        testLoginData.user = User.parseFromObject(testLoginData.user);
        commit(authStoreMutations.SAVE_LOGIN, testLoginData);
        return testLoginData.user;
    },
};

export enum authStoreMutations {
    SAVE_LOGIN = 'saveLogin',
    CLEAR_LOGIN = 'clearLogin',
    SAVE_USER = 'saveUser',
    CLEAR_FAILED_QUEUE = 'clearFailedQueue',
    PUSH_FAILED_REQUEST = 'pushFailedRequest',
    SET_IS_REFRESHING = 'setIsRefreshing',
}

const mutations = {
    saveLogin: (state: any, payload: { user: User, access_token: string, refresh_token: string }) => {
        state[authStoreState.USER] = User.parseFromObject(payload.user);
        state[authStoreState.TOKEN] = payload.access_token;
        state[authStoreState.REFRESH_TOKEN] = payload.refresh_token;
    },
    clearLogin: (state: any) => {
        state[authStoreState.USER] = undefined;
        state[authStoreState.TOKEN] = undefined;
        state[authStoreState.REFRESH_TOKEN] = undefined;
    },
    saveUser: (state: any, user: any) => {
        state[authStoreState.USER] = User.parseFromObject(user);
    },
    clearFailedQueue: (state: any) => {
        state[authStoreState.FAILED_QUEUE] = [];
    },
    pushFailedRequest: (state: any, payload: { resolve: any, reject: any }) => {
      state[authStoreState.FAILED_QUEUE].push(payload);
    },
    setIsRefreshing: (state: any, isRefreshing: boolean) => {
        state[authStoreState.IS_REFRESHING_TOKEN] = isRefreshing;
    },
};

export enum authStoreGetter {
    TOKEN = 'token',
    USER = 'user',
    REFRESH_TOKEN = 'refreshToken',
    IS_REFRESHING_TOKEN = 'isRefreshingToken',
    FAILED_QUEUE = 'failedQueue',
}

const getters = {
    [authStoreGetter.TOKEN]: (state: any) => state[authStoreState.TOKEN],
    [authStoreGetter.USER]: (state: any): User => state[authStoreState.USER],
    [authStoreGetter.REFRESH_TOKEN]: (state: any) => state[authStoreState.REFRESH_TOKEN],
    [authStoreGetter.IS_REFRESHING_TOKEN]: (state: any) => state[authStoreState.IS_REFRESHING_TOKEN],
    [authStoreGetter.FAILED_QUEUE]: (state: any) => state[authStoreState.FAILED_QUEUE],
};

const authStore: Module<any, any> = {
    state: store,
    actions,
    mutations,
    getters,
};

export default authStore;
