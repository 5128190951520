import EntityBaseRepository from '@/api/repositories/EntityBaseRepository';
import {FindAllResponse} from '@/interfaces/FindAllResponse';
import UserRole from '@/models/user-attributes/UserRole';

export default class RoleRepository extends EntityBaseRepository {

    public loadAllRoles(tenantId: string): Promise<FindAllResponse<UserRole>> {
        return this.axiosClient.get(`role?draft=false&global=false&tenantId=${tenantId}`);
    }

    public loadSpecificRole(id: string): Promise<UserRole> {
        return this.axiosClient.get(`/role/${id}`);
    }

    public newRole(role: UserRole): Promise<any> {
        return this.axiosClient.post('role', role);
    }

    public deleteRole(id: string): Promise<any> {
        return this.axiosClient.delete(`/role/${id}`);
    }
}
