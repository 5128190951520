import User from '@/models/User';
import Address from '@/models/Address';
import {VersionControl} from '@/misc/VersionControl';
import {LicenseModels} from '@/misc/Enums/Constants';
import moment from 'moment';

// one week
const DELAY_THRESHOLD: number = 60 * 60 * 24 * 7;

/**
 * Simple modal for a tenant entity
 */
export default class Tenant extends VersionControl {

    /**
     * Turns a plain object to a class object
     * @param object
     */
    public static parseFromObject(object: Partial<Tenant>): Tenant {
        const tenant = new Tenant();
        if (object.users) {
            object.users = User.parseFromArray(object.users) as User[];
        }

        if (object.billingAddress) {
            object.billingAddress = Address.parseFromObject(object.billingAddress);
        }
        // The usersCount is not calculated at update
        Object.assign(tenant, object);
        return tenant;
    }

    public id!: string;
    public name!: string;
    public email!: string;
    public billingAddress?: Address;
    public billingAddressId!: string;
    public locations?: Location[];
    public licenseModel!: LicenseModels;
    public usedLicenses!: number;
    public active!: boolean;
    public users?: User[];
    public isTest?: boolean;
    public stripeId?: string;
    public paymentDelay?: string[];

    constructor() {
        super();
        this.billingAddress = new Address();
    }

    public copy(): Tenant {
        return Tenant.parseFromObject(this.parseToObject());
    }


    /**
     * Returns the class object as plain object representation
     * RN-391 just a swallow copy ... maybe this is not enough...
     */
    public parseToObject(): Partial<Tenant> {
        const tmp: any = {...this};
        tmp.billingAddress = {...tmp.billingAddress};
        return tmp;
    }

    /**
     * Return different colors for the max employees state
     * @param forText Add --text to change the text-color
     *
     * TODO maybe we can use this function later
     */
    public getColorForUserState(forText: boolean): string {
        const percentage = this.usedLicenses;
        let color;

        if (percentage < 50) {
            color = 'green';
        } else if (percentage < 75) {
            color = 'bar-yellow';
        } else if (percentage < 90) {
            color = 'orange';
        } else {
            color = 'red';
        }

        if (forText) {
            color += '--text';
        }

        return color;
    }

    /**
     * Returns true, if one delay  of the Tenant added with the  delay Threshold is in the past
     */
    public paymentDelayed(): boolean {
        const today = moment().unix();
        if (!this.paymentDelay) {
            return false;
        }
        const earliestDelay = Math.min(...this.paymentDelay.map((delay) => moment(delay).unix()));
        return earliestDelay + DELAY_THRESHOLD < today;
    }

    /**
     * Returns the days the Tenant has until he is in delay
     */
    public timeUntilDelay(): number {
        const today = moment().unix();
        if (!this.paymentDelay) {
            return 0;
        }

        const earliestDelay = Math.min(...this.paymentDelay.map((delay) => moment(delay).unix()));
        return Math.floor((earliestDelay + DELAY_THRESHOLD - today) / (1000 * 60 * 60 * 24));
    }
}
