import Vuex, { StoreOptions } from 'vuex';
import Vue from 'vue';
import VuexPersistence from 'vuex-persist';
import authStore from './stores/auth.store';
import tenantStore from './stores/tenant.store';
import customerStore from '@/stores/customer.store';
import userStore from '@/stores/user.store';
import jobStore from '@/stores/job.store';
import locationStore from '@/stores/location.store';
import jobOccurrenceStore from '@/stores/jobOccurrence.store';

Vue.use(Vuex);

// set up persistence storage plugin
const vuexPersistence = new VuexPersistence({
    key: process.env.VUE_APP_TITLE,
    storage: window.localStorage,
    reducer: (state: any) => ({
        auth: {
            token: state.auth.token,
            user: state.auth.user,
            refreshToken: state.auth.refreshToken,
        },
        tenant: {
            activeTenant: state.tenant.activeTenant,
        },
    }),
    filter: (mutation) => ['auth/saveLogin', 'auth/saveUser', 'auth/clearLogin', 'tenant/activeTenant', 'tenant/reset'].includes(mutation.type),
});

// set up store
const store: StoreOptions<any> = {
    state: {},
    modules: {
        auth: {
            namespaced: true,
            ...authStore,
        },
        tenant: {
            namespaced: true,
            ...tenantStore,
        },
        customer: {
            namespaced: true,
            ...customerStore,
        },
        user: {
            namespaced: true,
            ...userStore,
        },
        job: {
            namespaced: true,
            ...jobStore,
        },
        jobOccurrence: {
            namespaced: true,
            ...jobOccurrenceStore,
        },
        location: {
            namespaced: true,
            ...locationStore,
        },
    },
    plugins: [vuexPersistence.plugin],
    // strict: process.env.NODE_ENV !== 'production',
};
export default new Vuex.Store<any>(store);
