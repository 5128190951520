import EntityBaseRepository from '@/api/repositories/EntityBaseRepository';

export default class AnalyticsRepository extends EntityBaseRepository {

    public getNavSearchBarResults(queryData: string, filterData: string): Promise<any> {

        return this.axiosClient.get(`/analytics/search?query=${queryData}&types=${filterData}`);

    }

    public async getDashboardData(tenantId: string): Promise<any> {
        // RN-380 only as temp solution
        const chartDataRequest = this.axiosClient.get(`/analytics/dashboard/chartData?tenant=${tenantId}`);
        const customerCountRequest = this.axiosClient.get(`/analytics/dashboard/customerCount?tenant=${tenantId}`);
        const locationCountRequest = this.axiosClient.get(`/analytics/dashboard/locationCount?tenant=${tenantId}`);
        const notAssignedEmployeesRequest = this.axiosClient.get(`/analytics/dashboard/notAssignedEmployees?tenant=${tenantId}`);
        const notSignedTimesheetsRequest = this.axiosClient.get(`/analytics/dashboard/notSignedTimesheets?tenant=${tenantId}`);
        const userCountRequest = this.axiosClient.get(`/analytics/dashboard/userCount?tenant=${tenantId}`);
        const workSessionCountRequest = this.axiosClient.get(`/analytics/dashboard/workSessionCount?tenant=${tenantId}`);

        // RN-380 while implementing the new theme make everything an own request to improve loading speed

        const [ chartData, customerCount, locationCount, notAssignedEmployees, notSignedTimesheets,
            userCount, workSessionCount ] =
            await Promise.all([chartDataRequest, customerCountRequest, locationCountRequest,
                notAssignedEmployeesRequest, notSignedTimesheetsRequest, userCountRequest,
                workSessionCountRequest ]) as any;

        const dashboardData = {
            chartData: chartData.workSessionsPerMonth,
            customerCount: customerCount.customerCount,
            userCount: userCount.userCount,
            locationCount: locationCount.locationCount,
            workSessionCount: workSessionCount.workSessionCount,
        };

        return dashboardData;
    }
}
