import AuthBaseRepository from '@/api/repositories/AuthBaseRepository';
import User from '@/models/User';
import {LoginResponse, ResetPWBody} from '@/interfaces/Auth';

/**
 *  Auth repository that provides authentication api methods
 */
export default class AuthRepository extends AuthBaseRepository {

    public async login(payload: object): Promise<LoginResponse> {
        return this.axiosClient.post('login', payload);
    }

    public async validateHash(hash: string): Promise<void> {
        return this.axiosClient.post(`setPassword/${hash}?validate`);
    }

    public async setPassword(payload: { hash: string, password: string }): Promise<{ user: User, token: string, refreshToken: string }> {
        return this.axiosClient.post(`setPassword/${payload.hash}`, {password: payload.password});
    }

    public async resetPassword(login: ResetPWBody): Promise<void> {
        return this.axiosClient.post(`resetPassword`, login);
    }

    public async refreshToken(token: string): Promise<{ token: string, refreshToken: string, user: User }> {
        return this.axiosClient.post('refreshToken', { refreshToken: token });
    }
}
