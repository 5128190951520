import Parseable from '@/misc/Parseable';
import Job from '@/models/Job';
import {TimeDuration} from '@/helper/TimeDuration';

/**
 * A jobAction represents one realization of a Job at a specific Timeframe
 */
export default class JobOccurrence extends Parseable {

    public static parseFromObject(object: Partial<JobOccurrence>): JobOccurrence {
        const jobAction = new JobOccurrence();
        object.job = Job.parseFromObject(object.job!);
        Object.assign(jobAction, object);
        return jobAction;
    }

    public startTime!: TimeDuration;
    public job!: Job;
    public workSession?: string;
    public date?: string;
    public isFinished?: boolean;


    public parseToObject(): any {
        return {...this};
    }

    public copy(): JobOccurrence {
        return JobOccurrence.parseFromObject(this.parseToObject());
    }
}
