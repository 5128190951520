import {Permission} from '@/misc/Enums/permission.enum';
import {VersionControl} from '@/misc/VersionControl';
import User from '@/models/User';

/**
 * Defines a user role
 */
export default class UserRole extends VersionControl {

    public static parseFromObject(object: UserRole): UserRole {
        const role = new UserRole();
        Object.assign(role, object);
        return role;
    }

    public id!: string;
    public name!: string;
    public description: string = '';
    public global: boolean = false;
    public draft: boolean = true;
    public superAdmin: boolean = false;
    public tenantAdmin: boolean = false;
    public tenantId: string = '';
    public permissions: Permission[] = [];
    public users?: User[];

    public parseToObject(): any {
        return {...this};
    }
}
