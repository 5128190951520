
import Address from '@/models/Address';
import Tenant from '@/models/Tenant';
import Location from '@/models/Location';
import {VersionControl} from '@/misc/VersionControl';

export default class Customer extends VersionControl {

    /**
     * Turns a plain object to a class object
     * @param object
     */
    public static parseFromObject(object: Partial<Customer>): Customer {
        const customer = new Customer();
        Object.assign(customer, object);
        return customer;
    }

    public active?: boolean;
    public id?: string;
    public orderId?: string;
    public name?: string;
    public phone?: string;
    public accountantName?: string;

    public tenant?: string | Tenant;

    public createdAt!: Date;

    public locations: Location[] = [];
    public locationsCount!: number;

    public address?: Address;


    constructor() {
        super();
    }

    public copy(): Customer {
        return Customer.parseFromObject(this.parseToObject());
    }

    // RN-389 just a swallow copy ... maybe this is not enough...
    public parseToObject(): Partial<Customer> {
        const tmp: any = {...this};
        tmp.address = {...tmp.address};
        return tmp;
    }
}
