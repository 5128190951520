import {VersionControl} from '@/misc/VersionControl';
import moment from 'moment';
import {getAllWeekDaysByNumber} from '@/misc/Enums/WeekDay.ts';

/**
 * The TimeSchedule defines when and where a Job should occur
 */
export default class TimeSchedule extends VersionControl {
    public static parseFromObject(object: Partial<TimeSchedule>): TimeSchedule {
        const timeSchedule = new TimeSchedule();
        if (object.weekdays && object.weekdays.length > 0) {
            object.weekdays = object.weekdays.map(Number);
        } else {
            object.weekdays = getAllWeekDaysByNumber();
        }
        Object.assign(timeSchedule, object);
        return timeSchedule;
    }

    public id?: string;

    /**
     * Date when the TimeSchedule (and it's recurrence) starts
     */
    public startDate!: string | null;
    /**
     * The Day the selected Job end its recurrence
     */
    public endDate?: string | null;
    /**
     * Defines the weekdays the Job is scheduled, empty equals every day
     */
    public weekdays: number[] = [];
    /**
     * Defines the exclusions for the Job to be active, on these days there should be no JobOccurrences for the Job
     */
    public daysOff: string[] = [];
    /**
     * Defines the exclusions for the Job to be inactive, on these days there should be a JobOccurrences for the Job
     */
    public additionalDays: string[] = [];
    /**
     * plannedStart of the Job
     */
    public startTime?: { hour: number, minute: number };

    public parseToObject(): Partial<TimeSchedule> {
        return {...this};
    }

    constructor() {
        super();
        this.weekdays = getAllWeekDaysByNumber();
    }

    public copy(): TimeSchedule {
        return TimeSchedule.parseFromObject(this.parseToObject());
    }


    /**
     * Gets the weekday by the passed date and returns it
     * @param date
     */
    public getWeekDayByDate(date: Date): string {
        // gets the date of the job
        const currentDate: Date = new Date(date);

        // gets the weekday by the date
        const day = currentDate.getDay().toString();
        const availableDays: string[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        const translatedDate = availableDays[day];

        // returns the date by using regex
        return translatedDate!.match(/[a-z]+/)![0];
    }

    /**
     * Returns the start time from index as HH:mm
     * @param index
     */
    public getStartTime(index = 0): string | null {
        if (this.startTime) {
            return moment(this.startTime).format('HH:mm');
        } else {
            return null;
        }
    }


    /**
     * returns if the TimeSchedule is expired by the given dateEnd
     */
    public get isOutDated(): boolean {
        return moment(this.endDate).isAfter(moment());
    }
}


