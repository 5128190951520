import EntityBaseRepository from '@/api/repositories/EntityBaseRepository';
import Customer from '@/models/Customer';
import {CancelToken, CancelTokenSource} from 'axios';

export default class CustomerRepository extends EntityBaseRepository {

    private cancelTokenSourceOption: { cancelToken: CancelToken | undefined } = {cancelToken: undefined};
    private cancelTokenSource!: CancelTokenSource;

    constructor() {
        super();
        this.cancelTokenSource = this.cancelTokenFactory.source();
        this.cancelTokenSourceOption.cancelToken = this.cancelTokenSource.token;
    }

    public loadCustomers(tenantId: string): Promise<any> {
        return this.axiosClient.get(`customer?tenant=${tenantId}`, this.cancelTokenSourceOption);
    }

    // TODO add skip and limit
    public loadCustomerVc(id: string, skipAmount?: number, limitAmount?: number): Promise<any> {
        const skip = skipAmount ? '&skip=' + skipAmount : '';
        const limit = limitAmount ? '&limit=' + limitAmount : '';
        return this.axiosClient.get(`customer?vcOrigin=${id}&vcIsLogEntity=true&relations=vcAuthor,invoiceAddress${skip}${limit}`);
    }


    public loadColor(timeScheduleId: string): Promise<any> {
        return this.axiosClient.get(`colorMapper?source=${timeScheduleId}`, this.cancelTokenSourceOption);
    }

    public loadCustomer(customerId: string): Promise<any> {
        return this.axiosClient.get(`customer/${customerId}`, this.cancelTokenSourceOption);
    }

    public create(customer: Customer): Promise<any> {
        return this.axiosClient.post(`customer`, customer, this.cancelTokenSourceOption);
    }

    public edit(customer: Partial<Customer>): Promise<any> {
        return this.axiosClient.patch(`customer/${customer.id}`, customer, this.cancelTokenSourceOption);
    }

    public delete(customer: Partial<Customer>): Promise<any> {
        return this.axiosClient.delete(`customer/${customer.id}`, this.cancelTokenSourceOption);
    }

    /**
     * Cancels all request of this repository and applies a new cancel token
     */
    public cancelRequests() {
        this.cancelTokenSource.cancel('cancelled by ui');
        this.cancelTokenSource = this.cancelTokenFactory.source();
        this.cancelTokenSourceOption.cancelToken = this.cancelTokenSource.token;
    }
}
