import {ActionTree, Module} from 'vuex';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import JobRepository from '@/api/repositories/JobRepository';
import Job from '@/models/Job';

const jobRepository: JobRepository = RepositoryFactory.get('job');

enum jobState {
  JOBS = 'jobs',
  COLORS = 'colors',
}

const store = {
  [jobState.JOBS]: [],
  [jobState.COLORS]: {},
};

export enum jobStoreActions {
  CANCEL_REQUESTS_ACTION = 'cancelRequestsAction',
  UPDATE_JOB_ACTION = 'updateJobAction',
  LOAD_JOB_ACTION = 'loadJobAction',
  CREATE_JOB_ACTION = 'createJobAction',
  DELETE_JOB_ACTION = 'deleteJobAction',
  LOAD_JOBS_ACTION = 'loadJobsAction',
}

const actions: ActionTree<any, any> = {
  [jobStoreActions.CANCEL_REQUESTS_ACTION]: async () => {
    jobRepository.cancelRequests();
  },
  [jobStoreActions.UPDATE_JOB_ACTION]: async ({commit}, payload: {job: Job}): Promise<Job> => {
    // remove the occurrence now here. Maybe its useful in the future somewhere else, so do not delete it now
    const jobRaw = await jobRepository.updateJob(payload);
    commit(jobStoreMutations.UPDATE_JOBS, payload.job);
    return Job.parseFromObject(jobRaw);
  },
  [jobStoreActions.LOAD_JOB_ACTION]: async ({commit}, jobId: string): Promise<Job> => {
    const jobRaw = await jobRepository.getJob(jobId);
    return Job.parseFromObject(jobRaw);
  },
  [jobStoreActions.LOAD_JOBS_ACTION]: async ({commit}, locationId: string): Promise<Job[]> => {
    const jobsRaw = await jobRepository.loadJobs(locationId);
    commit(jobStoreMutations.STORE_JOBS, jobsRaw.records);
    return Job.parseFromArray(jobsRaw.records) as Job[];
  },
  [jobStoreActions.CREATE_JOB_ACTION]: async ({commit}, job: Job): Promise<any> => {
    // remove the occurrence now here. Maybe its useful in the future somewhere else, so do not delete it now
    const jobRaw = await jobRepository.createJob(job);
    const createdjob = Job.parseFromObject(jobRaw);
    commit(jobStoreMutations.STORE_JOBS, createdjob);
    return createdjob;
  },
  [jobStoreActions.DELETE_JOB_ACTION]: async ({commit}, jobId: string) => {
    await jobRepository.deleteJob(jobId);
    commit(jobStoreMutations.REMOVE_JOBS, jobId);
  },
};

export enum jobStoreMutations {
  STORE_JOBS = 'storeJobs',
  STORE_JOB = 'storeJob',
  UPDATE_JOBS = 'updateJobs',
  REMOVE_JOBS = 'removeJob',
  STORE_COLORS = 'storeColors',
}

const mutations = {
  [jobStoreMutations.STORE_JOBS]:
      (state: any, jobs: Job[]) => state[jobState.JOBS] = jobs,
  [jobStoreMutations.STORE_JOB]:
      (state: any, job: Job) => state[jobState.JOBS].push(job),
  [jobStoreMutations.UPDATE_JOBS]:
      (state: any, job: Job) => {
    const index = state[jobState.JOBS].findIndex((item: Job) => item.id === job.id);
    if (index >= 0) {
      state[jobState.JOBS].splice(index, 1, job);
    } else {
      state[jobState.JOBS].push(job);
    }
  },
  [jobStoreMutations.REMOVE_JOBS]: (state: any, jobId: string) => {
    const index = state[jobState.JOBS].findIndex((item: Job) => item.id === jobId);
    state[jobState.JOBS].splice(index, 1);
  },
  [jobStoreMutations.STORE_COLORS]: (state: any, colors: any) => state[jobState.COLORS] = colors,
};

export enum jobStoreGetter {
  JOBS = 'jobs',
  COLORS = 'colors',
}

const getters = {
  [jobStoreGetter.JOBS]: (state: any) => state[jobState.JOBS],
  [jobStoreGetter.COLORS]: (state: any) => state[jobState.COLORS],
};

const jobStore: Module<any, any> = {
  state: store,
  actions,
  mutations,
  getters,
};

export default jobStore;
