import Parseable from '@/misc/Parseable';

export default class Image extends Parseable {
    public static parseFromObject(object: Partial<Image>): Image {
        const image = new Image();
        Object.assign(image, object);
        return image;
    }

    public id!: string;

    public parseToObject(): Partial<Image> {
        return {...this};
    }

    public copy(): Image {
        return Image.parseFromObject(this.parseToObject());
    }
}
