import Parseable from '@/misc/Parseable';
import Image from '@/models/Image';
import {SubTopics} from '@/misc/Enums/SubTopics';
import {Conditions} from '@/misc/Enums/Constants';


export default class SubTopic extends Parseable {

    public static parseFromObject(object: Partial<SubTopic>): SubTopic {
        const topic = new SubTopic();
        if (object.photos) {
            object.photos = Image.parseFromArray(object.photos) as Image[];
        }
        Object.assign(topic, object);
        return topic;
    }

    public id?: string;
    public name?: SubTopics;
    public condition: Conditions = Conditions.NO_INFO;
    public comment?: string;
    public photos?: any[] = [];

    public parseToObject(): Partial<SubTopic> {
        return {...this};
    }

    public copy(): SubTopic {
        return SubTopic.parseFromObject(this.parseToObject());
    }
}

