/**
 *  These theme colors properties are a mix from material design specs:
 *  https://material.io/design/color/#color-theme-creation
 *  and from vuetify theme management:
 * https://vuetifyjs.com/en/customization/theme#theme
 */
export default {
    // REIN light theme colors
    'primary': '#395BF1',
    'primary-light': '#9cadf8',
    'secondary': '#172B4D',
    'accent': '#00A252',
    'error': '#D92E56',
    'decent-error': '#FDD6DF',
    'info': '#5CBBF6',
    'success': '#2FAA94',
    'decent-success': '#D0EBE8',
    'decent-warning': '#FEF1D9',
    'warning': '#F9A400',
    'disabled': '#DFE1E6',
    'decent-gray': '#F4F4F8',
    'surface-variant-3': '#EBEBEB',
    // material design specs colors
    // -- general
    'background': '#ffffff',
    'status-badge-background': '#ffffff',
    'background-variant': '#FAFBFC',
    'surface': '#ffffff',
    'surface-variant': '#F7F7F7',
    'box-border': '#EAEAEA',
    'icons': '#ACAFBB', // how it should be named??
    'navbar-icons': '#9B9EAC',
    'navbar-tooltip-text': '#18203F',
    'stepper-header-element': '#9B9EAC',
    // -- font
    'on-primary': '#ffffff',
    'on-secondary': '#ffffff',
    'on-navigation-bg': '#6D7286',
    'on-background': '#44475A', // '#6B778C', The value before
    'on-background-variant': '#18203F', // '#172B4D',
    'on-background-variant-2': '#DFE1E6',
    'on-highlighted-background': '#44475A',
    'on-surface': '#172B4D',
    'on-surface-variant': '#172B4D',
    'on-success': '#ffffff',
    'on-error': '#ffffff',
    'on-warning': '#ffffff',
    'on-info': '#ffffff',
    'on-tooltip': '#FFFFFF',
    'table-header': '#A5A8B3',
    'header': '#21262D',
    'subheader': '#18203F',
    'list-color': '#44475A',
    // colors for mitarbeiter profile image
    // ATTENTION: if employee colors are being updated, change the digitSum function in UserInitialsComponent accordingly.
    'employee_color_1': '#4FC2F8',
    'employee_color_2': '#FE8B66',
    'employee_color_3': '#9CCC66',
    'employee_color_4': '#9E9E9E',
    'employee_color_5': '#A0887E',
    'employee_color_6': '#4CD0E2',
    'employee_color_7': '#4BB6AC',
    'employee_color_8': '#5F97F6',
    'employee_color_9': '#9FA8DB',
    'employee_color_10': '#B39DDB',
    'user-white-border': '#fff',
    'user-icon': '#ffffff',
    // mics colors
    'highlight': '#FFF176',
    'bar-yellow': '#F7B500',
    'job-started': '#E9F7F2',
    'navigation-bar-bg': '#F4F4F8',
    'signature-background': '#EEEEEE',
    'dashboard-background': '#F3F5FF',
    'table-information': '#18203F',
    'table-sub-information': '#C4C4C4',
    'map-radius-border': '#B8C9E3',
    'map-radius-background': '#3282FC',
    'button-success': '#02875A',
    'form-underline': '#C2C2C2',
    'form-underline-variant': '#979fae', // this is a tint of #6B778C (on-background)
    'tooltip-background': '#000000',
    'toggle-button-inactive': '#5A606D',
    'menu-hover': '#F4F6FF',
    'disabled-btn': '#B0BDF9',
    'broom-box': '#E2E2E7',
    // dashboard icons
    'total-customer': '#5CBBF6',
    'total-employee': '#F7B500',
    'total-location': '#B39DDB',
    'total-workTime': '#00D086',
    // chart4
    'chart-fill-color': 'rgba(0, 208, 134, 0.2)',
    'chart-stroke-color': '#00D086',

    // calendar
    'calendar-week': '#E5E5E5',
    'calendar-present-day': '#E1F4FF',
    'calendar-current-time': '#EF494B',
    'empty-day': '#E7E7EE',

    // calendar event / job status colors
    'status-in-future': '#E1F2FF',
    'status-not-planned-event': '#FFE7D0',
    'status-ambiguous': '#F4F3F8',
    'status-not-touched': '#FFE1E1',
    'status-cancelled': '#FFE1E1',
    'status-in-progress': '#FFE7D0',
    'status-done': '#EFFAF6',
    'status-has-comment': '#172B4D',

    // job detail colors
    'comment_color': '#FB8C00',
    'images_color': '#4CAF50',

    // task color
    'task-default': '#BDBDBD',
    'task-blue': '#1867C0',
    'task-green': '#00D086',
    'task-red': '#FE7366',
    'task-yellow': '#FFBB00',

    // status icon
    'status-active': '#07C180',
    'status-inactive': '#FF0000', // TODO right color

    // togglebutton
    'toggle-font': '#5A606D',

    // webkit-scroll
    'scrollbar': '#EAEAF2',
};
