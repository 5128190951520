import Tenant from '@/models/Tenant';
import UserRole from '@/models/user-attributes/UserRole';
import Address from '@/models/Address';
import BillingDelivery from '@/models/user-attributes/BillingDelivery';
import Denomination from '@/models/user-attributes/Denomination';
import PaymentType from '@/models/user-attributes/PaymentType';
import Gender from '@/models/user-attributes/Gender';
import EmploymentType from '@/models/user-attributes/EmploymentType';
import {VersionControl} from '@/misc/VersionControl';

/**
 * Presents an user. A user is defined through its role.
 */
export default class User extends VersionControl {

    public static parseFromObject(object: Partial<User>): User {
        const user = new User();
        if (typeof object.role === 'object') { // sometimes this value is not populated
            user.role = UserRole.parseFromObject(object.role as UserRole);
        }
        if (object.tenant) {
            object.tenant = Tenant.parseFromObject(object.tenant);
        }

        if (!object.address) {
            // Create Address for user who have no address
            object.address = new Address();
        }
        object.address = Address.parseFromObject(object.address);

        Object.assign(user, object);
        return user;
    }

    // general attributes
    public id?: string;
    public email?: string;
    public firstName!: string;
    public lastName!: string;
    public userName?: string;
    public phone?: string;
    public roleId?: string;
    public role?: UserRole;
    public tenant?: Tenant;
    public tenantId?: string;
    public isExtern!: boolean;
    public externCompanyName?: string;
    public address?: Address;
    public active?: boolean;
    public personnelNumber?: string | null;
    public inviteAccepted!: boolean;
    public oneTimePassword?: string;
    public createdAt!: string;

    /**
     * secondary attributes
     */
    public maxWorkHours?: number | null;
    public euResident?: boolean;
    public residencePermitExpirationDate?: string;
    public workPermitExpirationDate?: string;
    public gender?: Gender;
    public employmentType?: EmploymentType;
    public healthInsurance?: string;
    public pensionInsurance?: boolean;
    public paymentType?: PaymentType;
    public vacationDays?: number;
    public birthday?: string;
    public firstDayInTenant?: string;
    public lastDayInTenant?: string;
    public denomination?: Denomination;
    public paymentInterval?: number;
    public billingDelivery?: BillingDelivery;
    public jobDescription?: string;
    public timeLimit?: string;
    public iban?: string;
    public bankName?: string;
    public socialSecurityNumber?: string;
    public taxId?: string;
    public taxClass?: string;
    public childAllowance?: number;
    public managedLocations?: Location[];

    public userHasNoEmail?: boolean;

    constructor() {
        super();
        this.role = new UserRole();
        this.address = new Address();
        // at creating user set it to false. After saving, it will be undefined
        this.isExtern = false;
    }

    public parseToObject(): Partial<User> {
        const tmp: any = {...this};
        tmp.role = {...tmp.role};
        return tmp;
    }

    public copy(): User {
        return User.parseFromObject(this.parseToObject());
    }

    public get initials(): string {
        if (this.firstName && this.lastName) {
            return `${this.firstName[0]}${this.lastName[0]}`.toUpperCase();
        } else {
            return '';
        }
    }

    public get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    /**
     * Get user color (depends on the full name of the user)
     */
    public get userColor(): string {
        return `employee_color_${this.digitSum(this.fullName)}`;
    }
    /**
     * Convert string to integer value to show a 'random' color for the initials
     * @param target The name of the user
     * @param maxValue The maxValue of the color that should be displayed. Default is 10
     */
    public digitSum(target: string, maxValue: number = 10) {
        if (target.length === 0) {
            return 0;
        }

        // Get the lowerCase letters of the full name
        const letters = target.toLowerCase().split('');
        // Get the letters of the name. Numbers and special characters are ignored
        const array = letters.filter((char) => char.match(/[a-z]/) !== null);
        // Get the char codes
        const charCodes = array.map((char) => char.charCodeAt(0));
        // Concatenating the charCodes
        let charCodeString = charCodes.join('');

        let sum;
        // do while the sum is grater than the maxValue + 1
        do {
            // Get the digits of the charCodeString with .split() and parse the numbers to int for sure
            const splitted = charCodeString.split('').map((num) => parseInt(num));
            // Reduce the array by sum every number. In the next cycle this will happen to the reduced array and so on
            sum = splitted.reduce((a, b) => a + b, 0);

            if (maxValue < 9) {
                sum = parseInt(sum.toString());
            }

            charCodeString = sum.toFixed(0);
        } while (sum > maxValue + 1);

        return sum === 1 ? sum : sum - 1; // -1 cause sum is always > 0
    }
}
