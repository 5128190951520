
export enum Permission {
    JOB_READ_ALL = 'JOB_READ_ALL',
    JOB_READ_OWN = 'JOB_READ_OWN',
    JOB_CREATE_ALL = 'JOB_CREATE_ALL',
    JOB_UPDATE_ALL = 'JOB_UPDATE_ALL',
    JOB_UPDATE_OWN = 'JOB_UPDATE_OWN',
    JOB_DELETE_ALL = 'JOB_DELETE_ALL',
    JOB_DELETE_OWN = 'JOB_DELETE_OWN',
    LOCATION_READ_ALL = 'LOCATION_READ_ALL',
    LOCATION_READ_OWN = 'LOCATION_READ_OWN',
    LOCATION_CREATE_ALL = 'LOCATION_CREATE_ALL',
    LOCATION_UPDATE_ALL = 'LOCATION_UPDATE_ALL',
    LOCATION_UPDATE_OWN = 'LOCATION_UPDATE_OWN',
    LOCATION_DELETE_ALL = 'LOCATION_DELETE_ALL',
    LOCATION_DELETE_OWN = 'LOCATION_DELETE_OWN',
    ROLE_READ_ALL = 'ROLE_READ_ALL',
    ROLE_READ_OWN = 'ROLE_READ_OWN',
    ROLE_CREATE_ALL = 'ROLE_CREATE_ALL',
    ROLE_UPDATE_ALL = 'ROLE_UPDATE_ALL',
    ROLE_DELETE_ALL = 'ROLE_DELETE_ALL',
    TENANT_READ_ALL = 'TENANT_READ_ALL',
    TENANT_READ_OWN = 'TENANT_READ_OWN',
    TENANT_CREATE_ALL = 'TENANT_CREATE_ALL',
    TENANT_UPDATE_ALL = 'TENANT_UPDATE_ALL',
    TENANT_UPDATE_OWN = 'TENANT_UPDATE_OWN',
    TENANT_DELETE_ALL = 'TENANT_DELETE_ALL',
    TENANT_DELETE_OWN = 'TENANT_DELETE_OWN',
    TIMESHEET_READ_ALL = 'TIMESHEET_READ_ALL',
    TIMESHEET_READ_OWN = 'TIMESHEET_READ_OWN',
    TIMESHEET_CREATE_ALL = 'TIMESHEET_CREATE_ALL',
    TIMESHEET_UPDATE_ALL = 'TIMESHEET_UPDATE_ALL',
    TIMESHEET_UPDATE_OWN = 'TIMESHEET_UPDATE_OWN',
    TIMESHEET_DELETE_ALL = 'TIMESHEET_DELETE_ALL',
    TIMESHEET_DELETE_OWN = 'TIMESHEET_DELETE_OWN',
    USER_READ_ALL = 'USER_READ_ALL',
    USER_READ_OWN = 'USER_READ_OWN',
    USER_CREATE_ALL = 'USER_CREATE_ALL',
    USER_UPDATE_ALL = 'USER_UPDATE_ALL',
    USER_UPDATE_OWN = 'USER_UPDATE_OWN',
    USER_DELETE_ALL = 'USER_DELETE_ALL',
    USER_DELETE_OWN = 'USER_DELETE_OWN',
    WORKSESSION_READ_ALL = 'WORKSESSION_READ_ALL',
    WORKSESSION_READ_OWN = 'WORKSESSION_READ_OWN',
    WORKSESSION_CREATE_ALL = 'WORKSESSION_CREATE_ALL',
    WORKSESSION_UPDATE_ALL = 'WORKSESSION_UPDATE_ALL',
    WORKSESSION_UPDATE_OWN = 'WORKSESSION_UPDATE_OWN',
    WORKSESSION_DELETE_ALL = 'WORKSESSION_DELETE_ALL',
    WORKSESSION_DELETE_OWN = 'WORKSESSION_DELETE_OWN',
}

export const PermissionNull: string = '-';
export const PermissionTypes: string[] = new Array(
    ...new Set(
        Object.values(Permission)
            .map((value: string) => value.split('_')[0])));

export const PermissionObjects = PermissionTypes.map((type: string) => ({
    name: type,
    desc: 'DESCRIPTION.' + type,
}));

export const PermissionLevels: string[] = [
    'READ' ,
    'CREATE',
    'UPDATE',
    'DELETE',
];
