import {RepositoryFactory} from '@/api/RepositoryFactory';
import {ActionTree, Module} from 'vuex';
import Location from '@/models/Location';
import LocationRepository from '@/api/repositories/LocationRepository';
import DBFile from '@/interfaces/DBFile';
import {FindAllResponse} from '@/interfaces/FindAllResponse';
import {AxiosResponse} from 'axios';

const locationRepository: LocationRepository = RepositoryFactory.get('location');

enum locationStoreState {
    LOCATIONS = 'locations',
    LOCATION = 'location',
}

const store = {
    /**
     * All locations from the active tenant
     */
    [locationStoreState.LOCATIONS]: [],
    /**
     * One location populated with more infos
     */
    [locationStoreState.LOCATION]: undefined,
};

export enum locationStoreActions {
    CREATE_LOCATION_ACTION = 'createLocationAction',
    EDIT_LOCATION_ACTION = 'editLocationAction',
    SET_LOCATION_STATUS_ACTION = 'setLocationStatusAction',
    DELETE_LOCATION_ACTION = 'deleteLocationAction',
    LOAD_LOCATION_ACTION = 'loadLocationAction',
    LOAD_LOCATION_ORIGIN = 'loadLocationOrigin',
    LOAD_LOCATIONS_ACTION = 'loadLocationsAction',
    LOAD_CUSTOMER_LOCATIONS_ACTION = 'loadCustomerLocationsAction',
    CREATE_FILE_ACTION = 'createFileAction',
    DELETE_FILE_ACTION = 'deleteFileAciton',
    DELETE_DIVERSION_ACTION = 'deleteDiversionAction',
    DOWNLOAD_FILE = 'downloadFile',
}

const actions: ActionTree<any, any> = {
    [locationStoreActions.CREATE_LOCATION_ACTION]:
        async ({commit}, payload: { location: Location, store: boolean }): Promise<Location> => {
            const locationRaw = await locationRepository.create(payload.location);
            const createdLocation = Location.parseFromObject(locationRaw);
            commit(locationStoreMutations.ADD_LOCATION, createdLocation);
            if (payload.store) {
                commit(locationStoreMutations.STORE_LOCATION, createdLocation);
            }
            return createdLocation;
        },
    [locationStoreActions.EDIT_LOCATION_ACTION]: async ({commit}, payload: {location: Location, store: boolean}): Promise<Location> => {
        // use copy and delete associated values
        const locationRaw = await locationRepository.edit(payload.location);
        const editedLocation = Location.parseFromObject(locationRaw);
        if (store) {
            commit(locationStoreMutations.UPDATE_LOCATION, editedLocation);
        }
        return editedLocation;
    },
    [locationStoreActions.SET_LOCATION_STATUS_ACTION]: async ({commit}, payload: { location: Location}): Promise<Location> => {
        const alteredLocation: Partial<Location> = {id: payload.location.id, active: payload.location.active};
        const locationRaw = await locationRepository.edit(alteredLocation);
        const editedLocation = Location.parseFromObject(locationRaw);
        if (store) {
            commit(locationStoreMutations.UPDATE_LOCATION, editedLocation);
        }
        return editedLocation;
    },
    [locationStoreActions.DELETE_LOCATION_ACTION]: async ({commit}, locationId: string) => {
        await locationRepository.delete(locationId);
        commit(locationStoreMutations.REMOVE_LOCATION, location);
    },
    [locationStoreActions.LOAD_LOCATION_ACTION]:
        async ({commit}, payload: { locationId: string, store: boolean }): Promise<Location> => {
            const locationRaw = await locationRepository.loadLocation(payload.locationId);
            const location = Location.parseFromObject(locationRaw);
            if (payload.store) {
                commit(locationStoreMutations.STORE_LOCATION, location);
            }
            return location;
        },
    [locationStoreActions.LOAD_LOCATION_ORIGIN]:
        async ({commit}, locationId: string, skip?: number, limit?: number): Promise<Location[]> => {
            const locationsRaw = await locationRepository.loadLocationVc(locationId, { skip, limit }).then((value) => value.records);
            return Location.parseFromArray(locationsRaw) as Location[];
        },
    [locationStoreActions.LOAD_LOCATIONS_ACTION]:
        async ({commit}, payload: { tenantId: string, relations?: string[], store?: boolean }): Promise<Location[]> => {
            const locationsRaw = await locationRepository.loadLocations(payload.tenantId, payload.relations).then((value) => {
                return value.records;
            });
            const locations = Location.parseFromArray(locationsRaw) as Location[];
            commit(locationStoreMutations.STORE_LOCATIONS, locations);
            return locations;
        },
    [locationStoreActions.LOAD_CUSTOMER_LOCATIONS_ACTION]:
        async ({commit}, payload: { customerId: string, relations?: string[], store: boolean }): Promise<Location[]> => {
            const locationsRaw = await locationRepository.loadCustomersLocations(payload.customerId, payload.relations);
            const locations = Location.parseFromArray(locationsRaw) as Location[];
            commit(locationStoreMutations.STORE_LOCATIONS, locations);
            return locations;

        },
    [locationStoreActions.CREATE_FILE_ACTION]:
        async ({commit}, payload: { locationId: string, files: FormData }): Promise<FindAllResponse<DBFile>> => {
            payload.files.append('locationId', payload.locationId);
            return await locationRepository.createFiles(payload.locationId, payload.files);
        },
    [locationStoreActions.DELETE_FILE_ACTION]:
        async ({commit}, payload: {locationId: string, fileId: string} ): Promise<void> => {
            return await locationRepository.deleteFile(payload.locationId, payload.fileId);
        },
    [locationStoreActions.DELETE_DIVERSION_ACTION]:
        async ({commit}, diversionId: string ): Promise<void> => {
            return await locationRepository.deleteDiversion(diversionId);
        },
    [locationStoreActions.DOWNLOAD_FILE]:
        async ({commit}, payload: {locationId: string, fileId: string }): Promise<AxiosResponse> => {
            return await locationRepository.downloadFile(payload.locationId, payload.fileId);
        },
};

export enum locationStoreMutations {
    ADD_LOCATION = 'addLocation',
    STORE_LOCATION = 'storeLocation',
    STORE_LOCATIONS = 'storeLocations',
    REMOVE_LOCATION = 'removeLocation',
    UPDATE_LOCATION = 'updateLocation',
}

const mutations = {
    [locationStoreMutations.ADD_LOCATION]: (state: any, location: Location) => state[locationStoreState.LOCATIONS].push(location),
    [locationStoreMutations.STORE_LOCATION]: (state: any, location: Location) => state[locationStoreState.LOCATION] = location,
    [locationStoreMutations.STORE_LOCATIONS]: (state: any, locations: Location[]) => state[locationStoreState.LOCATIONS] = locations,
    [locationStoreMutations.REMOVE_LOCATION]: (state: any, location: Location) => {
        const index = state[locationStoreState.LOCATIONS].findIndex((item: Location) => item.id === location.id);
        state[locationStoreState.LOCATIONS].splice(index, 1);
    },
    [locationStoreMutations.UPDATE_LOCATION]: (state: any, location: Location) => {
        const index = state[locationStoreState.LOCATIONS].findIndex((item: Location) => item.id === location.id);
        state[locationStoreState.LOCATIONS].splice(index, 1, location);
    },
};

export enum locationStoreGetter {
    LOCATION = 'location',
    LOCATIONS = 'locations',
}

const getters = {
    [locationStoreGetter.LOCATION]: (state: any) => state[locationStoreState.LOCATION],
    [locationStoreGetter.LOCATIONS]: (state: any) => state[locationStoreState.LOCATIONS],
};

const locationStore: Module<any, any> = {
    state: store,
    actions,
    mutations,
    getters,
};

export default locationStore;



