export enum LicenseModels {
    LOCATIONS = 'locations',
    USERS = 'users',
}

export function getLicenseModels(): LicenseModels[] {
    return Object.values(LicenseModels);
}
export enum Conditions {
    GOOD = 1,
    NO_INFO = 0,
    RESOLVED = -1,
    PARTLY_RESOLVED = -2,
    NOT_RESOLVED = -3,
}

export enum TrafficAreas {
    URBAN = 'URBAN',
    RURAL = 'RURAL',
    HIGHWAY = 'HIGHWAY',
    OTHER = 'OTHER',
}
export function getTrafficAreas(): TrafficAreas[] {
    return Object.values(TrafficAreas);
}

export enum GeometryTypes {
    LINESTRING = 'LINESTRING',
    POINT = 'POINT',
    POLYGON = 'POLYGON',
}

