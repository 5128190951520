import store from '@/store';
import {Permission} from '@/misc/Enums/permission.enum';
import User from '@/models/User';

const user = (): User => {
    return store.getters['auth/user'];
};
/**
 * Vue plugin for permission handling based of the current user role. Provides support function about user permissions and user
 * role handling also.
 */
const UserRoleHandler = {
    install(Vue: any) {

        /**
         * Returns the current user role. If no user is defined, it returns undefined.
         */
        const getUserRole = () => {
            if (user()) {
                return user().role!.name;
            } else {
                return undefined;
            }
        };

        /**
         * Checks if user has the given role. If no user is defined, it returns undefined.
         * @param role
         */
        const hasUserRole = (role: string) => {
            if (user()) {
                return user().role!.name === role;
            } else {
                return undefined;
            }
        };

        // TODO: find out whether it is possible to generate functions to check the role of the user for each role like
        // for (Keys in userRoles) => [is'role']: (user)

        /**
         * Checks if the current user has the given permission
         * @param permission
         */
        const hasUserPermission = (permission: Permission): boolean => {
            if (isSuperAdmin()) {
                return true;
            }

            if (isTenantAdmin() &&
                (!permission.includes('TENANT')
                    || permission === Permission.TENANT_READ_OWN
                    || permission === Permission.TENANT_UPDATE_OWN)) {
                return true;
            }
            if (permission.endsWith('OWN')) {
                const perm = permission.replace('_OWN', '') as Permission;
                return (user().role!.permissions.includes(permission) || user().role!.permissions.includes(perm));
            }
            return user().role!.permissions.includes(permission);
        };

        // TODO add this.global and this.draft
        const isSuperAdmin = () => {
            if (user()) {
                return user().role!.superAdmin;
            } else {
                return undefined;
            }
        };
        const isTenantAdmin = () => {
            if (user()) {
                return user().role!.tenantAdmin;
            } else {
                return undefined;
            }
        };

        const isTestUser = () => {
            if (user()) {
                  return user().role!.superAdmin ? false : user().tenant!.isTest;
            } else {
                return undefined;
            }
        };

        const userRoleFns = {
            isTestUser,
            isTenantAdmin,
            hasPermission: hasUserPermission,
            getRole: getUserRole,
            hasRole: hasUserRole,
            isSuperAdmin,
        };

        Vue.prototype.$userRoleHandler = userRoleFns;
        Vue.userRoleHandler = userRoleFns;
    },
};
export default UserRoleHandler;
