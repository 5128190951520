import store from './../store';
import {Vue} from 'vue-property-decorator';
import {Permission} from '@/misc/Enums/permission.enum';

/**
 * This class contains a bunch of routing helper functions
 */
export class RoutingHelper {

    /**
     * Checks if the token is available and if the user has the correct rights. If no token is set, redirect user to login.
     * Has the current user not the permission to see the targeted route, show an error.
     * @param to
     * @param from
     * @param next
     * @param permission
     */
    public static async checkRouteAccess(to: any, from: any, next: any, permission: Permission) {
        if (store.getters['auth/token']) {
            if (Vue.userRoleHandler.hasPermission(permission)) {
                next();
            } else {
                next({name: 'error'});
            }
        } else {
            next({name: 'login'});
        }
    }

    /**
     * Checks if login view can be accessed. If a token is available, the login is not callable.
     * @param to
     * @param from
     * @param next
     */
    public static async checkLoginAccess(to: any, from: any, next: any) {
        if (store.getters['auth/token']) {
            // Load the activetenant for routing
            const activeTenant = store.getters['tenant/activeTenant'];
            const tenantId = activeTenant.id;
            next({name: RoutingHelper.getDefaultRoute(), params: {tenantId}});
        } else {
            next();
        }
    }

    /**
     * Returns the default route name for the current user depending on his role
     */
    public static getDefaultRoute(): string {
        try {
            if (Vue.userRoleHandler.isSuperAdmin()) {
                return 'tenantsOverview';
            } else if (Vue.userRoleHandler.hasPermission(Permission.JOB_READ_OWN)) {
                return 'jobsOverview';
            } else {
                return 'locationOverview';
            }
        } catch (e) {
            return 'login';
        }
    }
}

