import User from '@/models/User';
import Location from '@/models/Location';
import TimeSchedule from '@/models/TimeSchedule';
import Parseable from '@/misc/Parseable';
import {VersionControl} from '@/misc/VersionControl';
import Tenant from '@/models/Tenant';
import WorkSession from '@/models/WorkSession';

/**
 * A job represents the scheduled, recurring Action from a User on a location
 */
export default class Job extends VersionControl {

    public static parseFromObject(object: Partial<Job>): Job {
        const job = new Job();
        if (object.timeSchedule) {
            object.timeSchedule = TimeSchedule.parseFromObject(object.timeSchedule!);
        }
        if (object.location) {
            object.location = Location.parseFromObject(object.location!);
        }
        if (object.driver) {
            object.driver = User.parseFromObject(object.driver!);
        }
        Object.assign(job, object);
        return job;
    }

    public id?: string;
    /**
     * Drivers which are allowed to do the Job. this set is composed of Drivers from location
     */
    public driver?: User;
    public driverId?: string;


    /**
     * the time and place for the Job
     */
    public timeSchedule!: TimeSchedule;
    public timeScheduleId!: string;
    public location?: Location;
    public locationId!: string;
    public active?: boolean;
    public tenantId?: boolean;
    public tenant?: Tenant;
    public workSessions?: WorkSession[];

    constructor() {
        super();
        this.timeSchedule = new TimeSchedule();
        this.location = new Location();
    }

    public parseToObject(): Partial<Job> {
        const tmp: any = {...this};
        tmp.TimeSchedule = tmp.TimeSchedule.parseToObject();
        tmp.location = tmp.location.parseToObject();
        tmp.workSessions = tmp.workSessions.slice();
        return tmp;
    }

    public copy(): Job {
        return Job.parseFromObject(this.parseToObject());
    }
}
