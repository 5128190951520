import {ActionTree, Module} from 'vuex';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import JobOccurrenceRepository from '@/api/repositories/JobOccurrenceRepository';
import JobOccurrence from '@/models/JobOccurrence';
import WorkSession from '@/models/WorkSession';
import JobOccurrencesFilterData from '@/misc/JobOccurrencesFilterData';
import {WorkSessionFilterData} from '@/helper/WorksessionFilterData';
import Job from '@/models/Job';
import TimeSchedule from '@/models/TimeSchedule';
import User from '@/models/User';
import Address from '@/models/Address';
import GeoPosition from '@/models/GeoPosition';
import {Conditions, GeometryTypes, TrafficAreas} from '@/misc/Enums/Constants';
import UserRole from '@/models/user-attributes/UserRole';
import PaymentType from '@/models/user-attributes/PaymentType';
import Location from '@/models/Location';
import Customer from '@/models/Customer';
import {getTopics, Topics} from '@/misc/Enums/Topics';
import Topic from '@/models/Topic';
import SubTopic from '@/models/SubTopic';
import {SubTopics} from '@/misc/Enums/SubTopics';
import Note from '@/models/Note';
import {FindAllResponse} from '@/interfaces/FindAllResponse';

const jobOccurrenceRepository: JobOccurrenceRepository = RepositoryFactory.get('jobOccurrence');

enum jobOccurrenceStoreState {
    JOB_OCCURRENCES_CACHE = 'jobOccurrencesCache',
    JOB_OCCURRENCES = 'jobOccurrences',
    LOADING = 'loading',
}

const store = {
    /**
     * Cache for all jobOccurrences that were loaded
     */
    [jobOccurrenceStoreState.JOB_OCCURRENCES_CACHE]: {},
    /**
     * JobOccurrences that are from a particular period of time. Loaded either fresh from backend or retrieved from cache.
     */
    [jobOccurrenceStoreState.JOB_OCCURRENCES]: [],
    /**
     * A flag that indicates if an api request for jobOccurrence retrieving is performed
     */
    [jobOccurrenceStoreState.LOADING]: false,
};

/**
 * The limitation of how many dates can be cached
 */
const JOB_OCCURRENCE_CACHE_ENTRY_LIMIT = 365;

export enum jobOccurrenceStoreActions {
    CANCEL_REQUESTS_ACTION = 'cancelRequestsAction',
    LOAD_JOB_OCCURRENCES_ACTION = 'loadJobOccurrencesAction',
    LOAD_WORK_SESSION_ACTION = 'loadWorkSessionAction',
    LOAD_WORK_SESSIONS_ACTION = 'loadWorkSessionsAction',
    LOAD_WORK_SESSION_IMAGES = 'loadWorkSessionImages',
    LOAD_WORK_SESSION_NOTES_ACTION = 'loadWorkSessionNotesAction',
    CREATE_WORK_SESSION_NOTE_ACTION = 'createWorkSessionNoteAction',
    EDIT_WORK_SESSION_NOTE_ACTION = 'editWorkSessionNoteAction',
    DELETE_WORK_SESSION_NOTE_ACTION = 'deleteWorkSessionNoteAction',
    DOWNLOAD_WORK_SESSIONS_PDF = 'downloadPDFAction',
}

const actions: ActionTree<any, any> = {
    [jobOccurrenceStoreActions.CANCEL_REQUESTS_ACTION]: async () => {
        jobOccurrenceRepository.cancelRequests();
    },
    /**
     * Loads jobOccurrences either from cache or from api based on the queryData.
     * JobOccurrences from api are cached in store and in normal jobOccurrences store.
     * @param commit
     * @param getters through named deconstruction accessible via realGetter. This was necessary to avoid breaching of no-shadow linter rule
     * @param payload
     */
    [jobOccurrenceStoreActions.LOAD_JOB_OCCURRENCES_ACTION]:
        async ({commit, getters: realGetter},
               payload: { tenantId: string, filterData: JobOccurrencesFilterData, discardCache?: boolean }): Promise<JobOccurrence[]> => {
        commit(jobOccurrenceStoreMutations.STORE_LOADING, true);
        // check cache, if too high, clear jobOccurrences storage
        if (realGetter[jobOccurrenceStoreGetter.JOB_OCCURRENCES_CACHE_LENGTH] > JOB_OCCURRENCE_CACHE_ENTRY_LIMIT || payload.discardCache) {
            commit(jobOccurrenceStoreMutations.CLEAR_CACHED_JOB_OCCURRENCES);
        }

        // Get Max Amount of JobOccurrence Queries at the same time from
        // the environment, else use 8 as default
        const maxJobOccurrences = parseInt(`${process.env.VUE_APP_MAX_JOB_OCCURRENCE_REQUESTS}`) || 8;

        // Get Query Data of Payload, and create a temporary array for holding our chunks
        const queryData: Array<{ date: string, query: string }> = payload.filterData.queryData;

        // Method which flattens a multidimensional array to a single dimension
        const getFlattenedJobOccurrenceArray = (resolvedJobOccurrences: JobOccurrence[][]) => {
            let jobOccurrences: JobOccurrence[] = [];
            for (const jobOccurrenceArray of resolvedJobOccurrences) {
                jobOccurrences = jobOccurrences.concat(JobOccurrence.parseFromArray(jobOccurrenceArray) as JobOccurrence[]);
            }
            return jobOccurrences;
        };

        // Method to Save JobOccurrences in Cache
        const saveJobOccurrencesInCache = (jobOccurrenceQueryData: Array<{ date: string, query: string }>, jobOccurrences: JobOccurrence[][]) => {
            for (let i = 0; i < jobOccurrences.length; i++) {
                commit(jobOccurrenceStoreMutations.CACHE_JOB_OCCURRENCES, {[jobOccurrenceQueryData[i].date]: jobOccurrences[i]});
            }
        };

        // New method for loading jobOccurrences where, instead of loading in batches of 8, there are always 8 active requests.
        // Once one query is resolved, the next query is triggered.
        const promises: Array<{ promise: Promise<JobOccurrence[]>, trigger: () => void }> = queryData.map((item) => {
            // Default trigger for when the requested date was already cached
            let trigger: () => void = () => triggerNext();

            // If jobOccurrences are cached, just return them with the default trigger that doesn't do anything except calling the
            // next trigger
            const cachedJobOccurrences = realGetter[jobOccurrenceStoreGetter.DATE_CACHED_JOB_OCCURRENCES](item.date);
            if (cachedJobOccurrences) {
                return {
                    promise: cachedJobOccurrences,
                    trigger,
                };
            }
            // If jobOccurrences are not cached, return a promise that resolves to the retrieved jobOccurrences after triggered and then caches
            // them immediately
            const promise: Promise<JobOccurrence[]> = new Promise((resolve) => {
                trigger = async () => {
                    const resolved = await jobOccurrenceRepository.loadJobOccurrences(payload.tenantId, item.query);
                    const records = resolved.records;
                    records.forEach((occurrence) => occurrence.date = resolved.date);
                    resolve(records);
                    triggerNext();
                    saveJobOccurrencesInCache([item], [resolved.records]);
                };
            });
            return {promise, trigger};
        });

        let nextQuery = 0;
        // If not all queries are resolved, trigger the next promise
        const triggerNext = () => {
            if (nextQuery < queryData.length) {
                promises[nextQuery++].trigger();
            }
        };

        // Trigger the first maxJobOccurrences queries to get things started
        for (let i = 0; i < maxJobOccurrences; i++) {
            triggerNext();
        }

        const flattenedJobOccurrences = getFlattenedJobOccurrenceArray(
            await Promise.all(
                promises.map((val) => val.promise),
            ),
        );

        commit(jobOccurrenceStoreMutations.STORE_JOB_OCCURRENCES, flattenedJobOccurrences);
        commit(jobOccurrenceStoreMutations.STORE_LOADING, false);
        return realGetter[jobOccurrenceStoreGetter.JOB_OCCURRENCES];
    },
    /**
     * Simple loading function to get a workSession. This session is not saved in store.
     * @param commit
     * @param workSessionId
     */
    [jobOccurrenceStoreActions.LOAD_WORK_SESSION_ACTION]: async ({commit}, workSessionId: string): Promise<WorkSession> => {
        const rawWorkSession = await jobOccurrenceRepository.loadWorkSession(workSessionId);
        return WorkSession.parseFromObject(rawWorkSession);
    },
    [jobOccurrenceStoreActions.LOAD_WORK_SESSIONS_ACTION]: async ({commit}, filterData: WorkSessionFilterData): Promise<WorkSession[]> => {
        //
        const user = filterData.user ? `&user=${filterData.user}` : '';
        const location = filterData.location ? `&location=${filterData.location}` : '';

        // Time frame
        const createdAtFrom = filterData.createdAtFrom ? `&startDate=${filterData.createdAtFrom}` : '';
        const createdAtTo = filterData.createdAtTo ? `&endDate=${filterData.createdAtTo}` : '';
        const individualDates = filterData.individualDates.length ? `&individualDates=` + filterData.individualDates.join(',') : '';
        const TimeFrame = filterData.individualDates.length ? individualDates : createdAtFrom + createdAtTo;

        const queryString = `?tenantId=${filterData.tenant}${user}${location}${TimeFrame}`;

        // Get all WorkSessions from api
        const rawWorkSession = await jobOccurrenceRepository.getWorkSessions(queryString, ['user', 'location', 'topics', 'notes']);

        //  return as array
        return (WorkSession.parseFromArray(rawWorkSession.records) as WorkSession[]);

    },
    [jobOccurrenceStoreActions.DOWNLOAD_WORK_SESSIONS_PDF]: async ({commit}, workSessionId: string): Promise<any> => {
        return await jobOccurrenceRepository.downloadPDF(workSessionId);
    },
    [jobOccurrenceStoreActions.LOAD_WORK_SESSION_NOTES_ACTION]: async ({commit}, workSessionId: string): Promise<Note[]> => {
        const rawNotes = await jobOccurrenceRepository.loadWorkSessionNotes(workSessionId).then((value) => {
            return value.records;
        });
        return Note.parseFromArray(rawNotes) as Note[];
    },
    /**
     * Function to create a Note. The note will belong to the workSession in note.workSessionId
     * @param note note to create
     */
    [jobOccurrenceStoreActions.CREATE_WORK_SESSION_NOTE_ACTION]: async ({commit}, note: Note): Promise<Note> => {
        const rawNote = await jobOccurrenceRepository.createWorkSessionNote(note);
        return Note.parseFromObject(rawNote);
    },
    [jobOccurrenceStoreActions.EDIT_WORK_SESSION_NOTE_ACTION]: async ({commit}, note: Note): Promise<Note> => {
        const rawNote = await jobOccurrenceRepository.editWorkSessionNote(note);
        return Note.parseFromObject(rawNote);
    },
    [jobOccurrenceStoreActions.DELETE_WORK_SESSION_NOTE_ACTION]: async ({commit}, noteId: string) => {
      await jobOccurrenceRepository.deleteWorkSessionNote(noteId);

    },
    [jobOccurrenceStoreActions.LOAD_WORK_SESSION_IMAGES]: async ({commit}, workSessionId: string): Promise<any> => {
        return await jobOccurrenceRepository.getWorkSessionImages(workSessionId);
    },
};

export enum jobOccurrenceStoreMutations {
    CACHE_JOB_OCCURRENCES = 'cacheJobOccurrences',
    STORE_JOB_OCCURRENCES = 'storeJobOccurrences',
    CLEAR_JOB_OCCURRENCES = 'clearJobOccurrences',
    CLEAR_CACHED_JOB_OCCURRENCES = 'clearCachedJobOccurrences',
    STORE_LOADING = 'storeLoading',
}

const mutations = {
    /**
     * Cached jobOccurrence with given date and notifies getter
     * @param state
     * @param payload
     */
    [jobOccurrenceStoreMutations.CACHE_JOB_OCCURRENCES]: (state: any, payload: { date: string, jobOccurrences: JobOccurrence[] }) => {
        Object.assign(state[jobOccurrenceStoreState.JOB_OCCURRENCES_CACHE], payload);
        state[jobOccurrenceStoreState.JOB_OCCURRENCES_CACHE] = {...state[jobOccurrenceStoreState.JOB_OCCURRENCES_CACHE]}; // force notifying of getter
    },
    /**
     * Stores jobOccurrences
     * @param state
     * @param jobOccurrences
     */
    [jobOccurrenceStoreMutations.STORE_JOB_OCCURRENCES]: (state: any, jobOccurrences: JobOccurrence[]) =>
        state[jobOccurrenceStoreState.JOB_OCCURRENCES] = jobOccurrences,
    /**
     * Clears jobOccurrence storage
     * @param state
     */
    [jobOccurrenceStoreMutations.CLEAR_JOB_OCCURRENCES]: (state: any) =>
        state[jobOccurrenceStoreState.JOB_OCCURRENCES] = [],
    /**
     * Clears jobOccurrencesCache
     * @param state
     */
    [jobOccurrenceStoreMutations.CLEAR_CACHED_JOB_OCCURRENCES]: (state: any) =>
        state[jobOccurrenceStoreState.JOB_OCCURRENCES_CACHE] = {},
    /**
     * Stores the loading state
     * @param state
     * @param value
     */
    [jobOccurrenceStoreMutations.STORE_LOADING]: (state: any, value: boolean) =>
        state[jobOccurrenceStoreState.LOADING] = value,
};

export enum jobOccurrenceStoreGetter {
    JOB_OCCURRENCES_CACHE_LENGTH = 'jobOccurrencesCacheLength',
    JOB_OCCURRENCES = 'jobOccurrences',
    DATE_CACHED_JOB_OCCURRENCES = 'dateCachedJobOccurrences',
    LOADING = 'loading',
}

const getters = {
    /**
     * Returns the jobOccurrences store entry length
     * @param state
     */
    [jobOccurrenceStoreGetter.JOB_OCCURRENCES_CACHE_LENGTH]: (state: any): number =>
        Object.keys(state[jobOccurrenceStoreState.JOB_OCCURRENCES_CACHE]).length,
    /**
     * Returns all stored jobOccurrences
     * @param state
     */
    [jobOccurrenceStoreGetter.JOB_OCCURRENCES]: (state: any): JobOccurrence[] => state[jobOccurrenceStoreState.JOB_OCCURRENCES],
    /**
     * Returns cached jobOccurrences for a given date
     * @param state
     */
    [jobOccurrenceStoreGetter.DATE_CACHED_JOB_OCCURRENCES]:
        (state: any) => (date: string): JobOccurrence[] => state[jobOccurrenceStoreState.JOB_OCCURRENCES_CACHE][date],
    /**
     * Returns loading value
     * @param state
     */
    [jobOccurrenceStoreGetter.LOADING]: (state: any) => state[jobOccurrenceStoreState.LOADING],
};

const jobOccurrenceStore: Module<any, any> = {
    state: store,
    actions,
    mutations,
    getters,
};

export default jobOccurrenceStore;
