export enum Topics {
    SIGNAGE = 'SIGNAGE',
    BEACON = 'BEACON',
    WARNING_LIGHTS = 'WARNING_LIGHTS',
    MARKS = 'MARKS',
    TRAFFIC_LIGHTS = 'TRAFFIC_LIGHTS',
    OTHERS = 'OTHERS',
}
/**
 * function to return the default Topics and all topics if all is true
 * @param all if this is true, function returns all topics.
 */
export function getTopics(all: boolean): Topics[] {
    return Object.values(Topics).filter((topic) => {
        if (!all) {
            return topic !== Topics.TRAFFIC_LIGHTS && topic !== Topics.MARKS && topic !== Topics.OTHERS;
        }
        return true;
    });
}
export function getTopicImage(topic: Topics) {
    switch (topic) {
        case Topics.SIGNAGE: return require('../../assets/images/TopicIcons/Sign.svg');
        case Topics.BEACON: return require('../../assets/images/TopicIcons/Beacon.svg');
        case Topics.WARNING_LIGHTS: return require('../../assets/images/TopicIcons/WarningLights.svg');
        case Topics.MARKS: return require('../../assets/images/TopicIcons/Marks.svg');
        case Topics.TRAFFIC_LIGHTS: return require('../../assets/images/TopicIcons/TrafficLights.svg');
        case Topics.OTHERS: return require('../../assets/images/TopicIcons/Others.svg');
    }
}
