import {ActionTree, Module} from 'vuex';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import UserRepository from '@/api/repositories/UserRepository';
import UserRole from '@/models/user-attributes/UserRole';
import User from '@/models/User';
import Tenant from '@/models/Tenant';
import EmploymentType from '@/models/user-attributes/EmploymentType';
import Denomination from '@/models/user-attributes/Denomination';
import BillingDelivery from '@/models/user-attributes/BillingDelivery';
import PaymentType from '@/models/user-attributes/PaymentType';
import Gender from '@/models/user-attributes/Gender';
import Customer from '@/models/Customer';
import {saveAs} from 'file-saver';

const userRepository: UserRepository = RepositoryFactory.get('user');

enum userStoreState {
    ACTIVE_USER = 'activeUser',
    USERS = 'users',
    ROLES = 'roles',
    EMPLOYMENT_TYPES = 'employmentTypes',
    DENOMINATIONS = 'denominations',
    BILLING_DELIVERIES = 'billingDeliveries',
    PAYMENT_TYPES = 'paymentTypes',
    GENDER = 'gender',
}

const store = {
    /**
     * the user that is currently logged in
     */
    [userStoreState.ACTIVE_USER]: undefined,
    /**
     * all users from a specific tenant
     */
    [userStoreState.USERS]: [],
    /**
     * all roles belonging to a tenant
     */
    [userStoreState.ROLES]: [],
    [userStoreState.EMPLOYMENT_TYPES]: [],
    [userStoreState.DENOMINATIONS]: [],
    [userStoreState.BILLING_DELIVERIES]: [],
    [userStoreState.PAYMENT_TYPES]: [],
    [userStoreState.GENDER]: [],
};

export enum userStoreActions {
    LOAD_USERS_ACTION = 'loadUsersAction',
    LOAD_USER_ORIGIN = 'loadUserOrigin',
    LOAD_USER_ACTION = 'loadUserAction',
    LOAD_USER_BY_EMAIL_ACTION = 'loadUserByEmailAction',
    CREATE_USER_ACTION = 'createUserAction',
    DELETE_USER_ACTION = 'deleteUserAction',
    EDIT_USER_ACTION = 'editUserAction',
    SET_USER_STATUS_ACTION = 'setUserStatusAction',
    LOAD_USER_ROLES_ACTION = 'loadUserRolesAction',
    DELETE_USER_ROLE_ACTION = 'deleteUserRoleAction',
    CREATE_USER_ROLE_ACTION = 'createUserRoleAction',
    UPDATE_USER_ROLE_ACTION = 'updateUserRoleAction',
    LOAD_USERS_ATTRIBUTES_ACTION = 'loadUsersAttributesAction',
    CHANGE_PASSWORD_ACTION = 'changePasswordAction',
    RESEND_INVITATION_ACTION = 'resendInvitationAction',
    DOWNLOAD_TIMESHEET = 'downloadTimesheet',
}

const actions: ActionTree<any, any> = {
    [userStoreActions.LOAD_USERS_ACTION]:
        async ({commit}, payload: { tenantId: string, relations?: string[] }): Promise<User[]> => {
            // Any string with length > 1 forces the queryOptions to have a 'true' value for the backend

            const usersRaw = await userRepository.loadUsers(payload.tenantId, payload.relations).then((value) => {
                return value.records;
            });
            const users = User.parseFromArray(usersRaw) as User[];

            commit(userStoreMutations.STORE_USERS, users);
            return users;
        },
    [userStoreActions.LOAD_USER_ORIGIN]: async ({commit}, userId: string, skip?: number, limit?: number) => {
        const usersRaw = await userRepository.loadUserVc(userId, { skip, limit }).then((value) => {
            return value.records;
        });
        return User.parseFromArray(usersRaw);
    },
    [userStoreActions.LOAD_USER_ACTION]: async ({commit, state}, userId: string) => {
        const userRaw = await userRepository.loadSingleUser(userId);
        const user = User.parseFromObject(userRaw);
        commit(userStoreMutations.STORE_ACTIVE_USER, user);
        return user;
    },
    [userStoreActions.LOAD_USER_BY_EMAIL_ACTION]: async ({commit, state}, email: string) => {
        const userRaw = await userRepository.loadUserByEmail(email).then((value) => {
            return value.records;
        });
        const users = User.parseFromArray(userRaw) as User[];
        commit(userStoreMutations.STORE_USERS, users);
        return users;
    },
    [userStoreActions.CREATE_USER_ACTION]: async ({commit}, user: User): Promise<User> => {
        const rawUser = await userRepository.createUser(user);
        const createdUser = User.parseFromObject(rawUser);
        commit(userStoreMutations.ADD_USER, createdUser);
        return createdUser;
    },
    [userStoreActions.DELETE_USER_ACTION]: async ({commit}, user) => {
        const userRaw = await userRepository.deleteUser(user).then((value) => {
            return value.records;
        });
        const deletedUser = Customer.parseFromObject(userRaw);
        commit(userStoreMutations.REMOVE_USER, deletedUser);
        return deletedUser;
    },
    [userStoreActions.EDIT_USER_ACTION]: async ({commit}, user: User): Promise<User> => {
        const userCopy = user.parseToObject();

        if (userCopy.tenant) {
            userCopy.tenantId = (userCopy.tenant! as Tenant).id;
        }
        const rawUpdatedUser = await userRepository.updateUser(userCopy);
        const updatedUser = User.parseFromObject(rawUpdatedUser);
        commit(userStoreMutations.STORE_ACTIVE_USER, updatedUser);
        return updatedUser;
    },
    [userStoreActions.SET_USER_STATUS_ACTION]:
        async ({commit}, payload: { id: string, active: boolean, preview?: boolean, force?: boolean }): Promise<User> => {
            const rawUpdatedUser = await userRepository.setUserStatus(payload);
            const updatedUser = User.parseFromObject(rawUpdatedUser);
            commit(userStoreMutations.STORE_ACTIVE_USER, updatedUser);
            return updatedUser;
        },
    [userStoreActions.LOAD_USER_ROLES_ACTION]: async ({state, commit}, payload: {tenantId: string, relations?: string[] }): Promise<UserRole[]> => {
        const roleRaw = await userRepository.loadUserRoles(payload.tenantId, payload.relations).then((value) => {
            return value.records;
        });
        const roles = UserRole.parseFromArray(roleRaw)as UserRole[];
        commit(userStoreMutations.SAVE_USER_ROLES, roles);
        const res =  roles as UserRole[];
        return roles;
    },
    [userStoreActions.CREATE_USER_ROLE_ACTION]:
        async ({commit}, role: UserRole): Promise<UserRole> => {
        let res = await userRepository.createNewUserRole(role);
        res = UserRole.parseFromObject(res);
        commit(userStoreMutations.STORE_USER_ROLE, res);
        return res;
    },
    [userStoreActions.DELETE_USER_ROLE_ACTION]:
        async ({commit}, id: string): Promise<any> => {
            await userRepository.deleteUserRole(id);
            commit(userStoreMutations.REMOVE_USER_ROLE, id);
    },
    [userStoreActions.UPDATE_USER_ROLE_ACTION]:
        async ({commit}, role: UserRole): Promise<UserRole> => {
            let res = await userRepository.updateUserRole(role);
            res = UserRole.parseFromObject(res);
            commit(userStoreMutations.STORE_USER_ROLE, res);
            return res;
    },
    [userStoreActions.LOAD_USERS_ATTRIBUTES_ACTION]: async ({commit}): Promise<any> => {
        // TODO: With cached Attributes??
        const attributes = await userRepository.loadUserAttributes();
        commit(userStoreMutations.SAVE_USER_GENDER, attributes.gender);
        commit(userStoreMutations.SAVE_USER_EMPLOYMENT_TYPES, attributes.employmentType);
        commit(userStoreMutations.SAVE_USER_DENOMINATIONS, attributes.denomination);
        commit(userStoreMutations.SAVE_USER_BILLING_DELIVERIES, attributes.billingDelivery);
        commit(userStoreMutations.SAVE_USER_PAYMENT_TYPES, attributes.paymentType);
        return attributes;
    },
    [userStoreActions.CHANGE_PASSWORD_ACTION]: async ({commit}, payload: { user: User, passwordOld: string, password: string }): Promise<void> => {
        await userRepository.changePassword(payload);
    },
    [userStoreActions.RESEND_INVITATION_ACTION]: async ({commit}, user: User): Promise<void> => {
        await userRepository.resendInvitation(user.id!);
    },
    [userStoreActions.DOWNLOAD_TIMESHEET]: async ({commit}, payload: {user: User, date: string}) => {
        const blob = await userRepository.downloadTimesheet(payload.user.id!, payload.date);
        saveAs(blob, `${payload.user.firstName}_${payload.user.lastName}_${payload.date}.pdf`);
        return blob;
    },
};

export enum userStoreMutations {
    STORE_ACTIVE_USER = 'storeActiveUser',
    STORE_USERS = 'storeUsers',
    ADD_USER = 'addUser',
    UPDATE_USER = 'updateUser',
    REMOVE_USER = 'removeUser',
    SAVE_USER_ROLES = 'saveUserRoles',
    SAVE_USER_GENDER = 'saveUserGender',
    SAVE_USER_EMPLOYMENT_TYPES = 'saveUserEmploymentTypes',
    SAVE_USER_DENOMINATIONS = 'saveUserDenominations',
    SAVE_USER_BILLING_DELIVERIES = 'saveUserBillingDeliveries',
    SAVE_USER_PAYMENT_TYPES = 'saveUserPaymentTypes',
    STORE_USER_ROLE = 'storeUserRole',
    REMOVE_USER_ROLE = 'removeUserRole',
}

const mutations = {
    [userStoreMutations.STORE_ACTIVE_USER]: (state: any, user: User) => state[userStoreState.ACTIVE_USER] = user,
    [userStoreMutations.ADD_USER]: (state: any, user: User) => state[userStoreState.USERS].push(user),
    [userStoreMutations.UPDATE_USER]: (state: any, user: User) => {
        const index = state[userStoreState.USERS].findIndex((item: User) => item.id === user.id);
        state[userStoreState.USERS].splice(index, 1, user);
    },
    [userStoreMutations.REMOVE_USER]: (state: any, user: User) => {
        const index = state[userStoreState.USERS].findIndex((item: User) => item.id === user.id);
        state[userStoreState.USERS].splice(index, 1);
    },
    [userStoreMutations.STORE_USERS]: (state: any, users: User[]) => state[userStoreState.USERS] = users,
    [userStoreMutations.SAVE_USER_ROLES]: (state: any, payload: any) => state[userStoreState.ROLES] = payload,
    [userStoreMutations.SAVE_USER_EMPLOYMENT_TYPES]: (state: any, payload: any) => state[userStoreState.EMPLOYMENT_TYPES] = payload,
    [userStoreMutations.SAVE_USER_DENOMINATIONS]: (state: any, payload: any) => state[userStoreState.DENOMINATIONS] = payload,
    [userStoreMutations.SAVE_USER_BILLING_DELIVERIES]: (state: any, payload: any) => state[userStoreState.BILLING_DELIVERIES] = payload,
    [userStoreMutations.SAVE_USER_PAYMENT_TYPES]: (state: any, payload: any) => state[userStoreState.PAYMENT_TYPES] = payload,
    [userStoreMutations.SAVE_USER_GENDER]: (state: any, payload: any) => state[userStoreState.GENDER] = payload,
    [userStoreMutations.STORE_USER_ROLE]: (state: any, role: UserRole) => {
        const index = state[userStoreState.ROLES].findIndex((item: UserRole) => item.id === role.id);
        // replace or add tenant object
        if (index >= 0) {
            state[userStoreState.ROLES].splice(index, 1, role);
        } else {
            state[userStoreState.ROLES].push(role);
        }
    },
    [userStoreMutations.STORE_USER_ROLE]: (state: any, role: UserRole) => {
        const index = state[userStoreState.ROLES].findIndex((item: UserRole) => item.id === role.id);
        // replace or add tenant object
        if (index >= 0) {
            state[userStoreState.ROLES].splice(index, 1, role);
        } else {
            state[userStoreState.ROLES].push(role);
        }
    },
    [userStoreMutations.REMOVE_USER_ROLE]: (state: any, roleId: string) => {
        const index = state[userStoreState.ROLES].findIndex((item: UserRole) => item.id === roleId);
        // replace or add tenant object
        if (index >= 0) {
            state[userStoreState.ROLES].splice(index, 1);
        }
    },
};

export enum userStoreGetter {
    ACTIVE_USER = 'activeUser',
    USERS = 'users',
    ROLES = 'roles',
    EMPLOYMENT_TYPES = 'employmentTypes',
    DENOMINATIONS = 'denominations',
    BILLING_DELIVERIES = 'billingDeliveries',
    PAYMENT_TYPES = 'paymentTypes',
    GENDER = 'gender',
}

const getters = {
    [userStoreGetter.ACTIVE_USER]: (state: any) => state[userStoreState.ACTIVE_USER],
    [userStoreGetter.USERS]: (state: any) => state[userStoreState.USERS],
    [userStoreGetter.ROLES]: (state: any): UserRole[] => state[userStoreState.ROLES],
    [userStoreGetter.EMPLOYMENT_TYPES]: (state: any): EmploymentType[] => state[userStoreState.EMPLOYMENT_TYPES],
    [userStoreGetter.DENOMINATIONS]: (state: any): Denomination[] => state[userStoreState.DENOMINATIONS],
    [userStoreGetter.BILLING_DELIVERIES]: (state: any): BillingDelivery[] => state[userStoreState.BILLING_DELIVERIES],
    [userStoreGetter.PAYMENT_TYPES]: (state: any): PaymentType[] => state[userStoreState.PAYMENT_TYPES],
    [userStoreGetter.GENDER]: (state: any): Gender[] => state[userStoreState.GENDER],
};

const userStore: Module<any, any> = {
    state: store,
    actions,
    mutations,
    getters,
};
export default userStore;
