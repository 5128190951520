import EntityBaseRepository from '@/api/repositories/EntityBaseRepository';
import Tenant from '@/models/Tenant';
import {FindAllResponse} from '@/interfaces/FindAllResponse';

/**
 * Repository that provides all methods related to tenant entity
 */
export default class TenantRepository extends EntityBaseRepository {

    public loadTenants(): Promise<FindAllResponse<Tenant>> {
        return this.axiosClient.get('tenant?relations=billingAddress');
    }

    public loadTenant(id: string): Promise<Tenant> {
        return this.axiosClient.get(`tenant/${id}`);
    }

    public loadTenantVc(id: string, options: { skip?: number, limit?: number }): Promise<FindAllResponse<Tenant>> {
        const queryOptions: string[] = [];
        Object.keys(options).forEach((key: string) => {
            if (options[key]) {
                queryOptions.push(`${key}=${options[key]}`);
            }
        });

        return this.axiosClient.get(`tenant/archive/${id}?${queryOptions.join('&')}`);
    }

    public create(tenant: Tenant): Promise<any> {
        return this.axiosClient.post('tenant', tenant);
    }

    public edit(tenant: Partial<Tenant>): Promise<any> {
        return this.axiosClient.patch(`tenant/${tenant.id}`, tenant);
    }

    public delete(tenant: Tenant): Promise<any> {
        return this.axiosClient.delete(`tenant/${tenant.id}`);
    }

    public startTest(): Promise<any> {
        return this.axiosClient.get('testEnvironment/start');
    }
}
