import EntityBaseRepository from '@/api/repositories/EntityBaseRepository';
import Job from '@/models/Job';
import {CancelToken, CancelTokenSource} from 'axios';

export default class JobRepository extends EntityBaseRepository {

    private cancelTokenSourceOption: { cancelToken: CancelToken | undefined } = {cancelToken: undefined};
    private cancelTokenSource!: CancelTokenSource;

    constructor() {
        super();
        this.cancelTokenSource = this.cancelTokenFactory.source();
        this.cancelTokenSourceOption.cancelToken = this.cancelTokenSource.token;
    }

    public getJob(jobId: string): Promise<any> {
        return this.axiosClient.get(`job/${jobId}`, this.cancelTokenSourceOption);
    }

    public loadJobs(locationId: string): Promise<any> {
        return this.axiosClient.get(`job?location=${locationId}&relations=timeSchedule&relations=location&relations=driver`);
    }

    public createJob(job: Job): Promise<any> {
        return this.axiosClient.post(`/job`, job, this.cancelTokenSourceOption);
    }

    public deleteJob(jobId: string) {
        return this.axiosClient.delete(`job/${jobId}`);
    }

    public updateJob(payload: {job: Job}): Promise<Job> {
        return this.axiosClient.patch(`job/${payload.job.id}`,
          payload.job, this.cancelTokenSourceOption);
    }

    public cancelRequests() {
        this.cancelTokenSource.cancel('cancelled by ui');
        this.cancelTokenSource = this.cancelTokenFactory.source();
        this.cancelTokenSourceOption.cancelToken = this.cancelTokenSource.token;
    }
}
