import Parseable from '@/misc/Parseable';
import {GeometryTypes} from '@/misc/Enums/Constants.ts';

export default class GeoPosition extends Parseable {


    /**
     * Turns a plain object to a class object
     * @param object
     */
    public static parseFromObject(object: Partial<GeoPosition>): GeoPosition {
        const geoPosition = new GeoPosition();
        geoPosition.type = object.type ? object.type : GeometryTypes.POINT;
        if (object.positions) {
            object.positions?.forEach((value) => geoPosition.positions.push(value));
        }
        return geoPosition;
    }

    public type!: GeometryTypes;
    public positions!: Array<{ lat: number; lng: number; }>;

    /**
     * Return a swallow object copy
     */
    public copy(): GeoPosition {
        return GeoPosition.parseFromObject(this.parseToObject());
    }

    public getLatLngOfFirst(): {lat: number, lng: number} {
        return {lat: this.positions[0].lat, lng: this.positions[0].lng};
    }

    constructor() {
        super();
        this.positions = [];
        this.type = GeometryTypes.POINT;
    }

    /**
     * Returns the class object as plain object representation
     */
    public parseToObject(): Partial<GeoPosition> {
        return {...this};
    }
}


