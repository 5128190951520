import Parseable from '@/misc/Parseable';
import {GeometryTypes} from '@/misc/Enums/Constants';
import GeoPosition from '@/models/GeoPosition';


/**
 * A Diversion is an alternate Route resulting out of the Work on a Location. It is Stored inside the Location
 */
export default class Diversion extends Parseable {


    /**
     * Turns a plain object to a class object
     * @param object
     */
    public static parseFromObject(object: Partial<Diversion>): Diversion {
        const diversion = new Diversion();
        if (object.geoPosition) {
            object.geoPosition = GeoPosition.parseFromObject(object.geoPosition);
        }
        Object.assign(diversion, object);
        return diversion;
    }


    public geoPosition?: GeoPosition;
    public id?: string;

    /**
     * Return a swallow object copy
     */
    public copy(): Diversion {
        return Diversion.parseFromObject(this.parseToObject());
    }

    constructor() {
        super();
    }

    /**
     * Returns the class object as plain object representation
     */
    public parseToObject(): Partial<Diversion> {
        return {...this};
    }
}
