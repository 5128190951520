import User from '@/models/User';
import Customer from '@/models/Customer';
import {VersionControl} from '@/misc/VersionControl';
import WorkSession from '@/models/WorkSession';
import Address from '@/models/Address';
import Job from '@/models/Job';
import {TrafficAreas} from '@/misc/Enums/Constants.ts';
import {getTopics, Topics} from '@/misc/Enums/Topics.ts';
import Tenant from '@/models/Tenant';
import DBFile from '@/interfaces/DBFile';
import Diversion from '@/models/Diversion';

export default class Location extends VersionControl {

    public static parseFromObject(object: Partial<Location>): Location {
        const location = new Location();
        if (typeof object.address === 'object') {
            object.address = Address.parseFromObject(object.address!);
        }
        object.jobs = Job.parseFromArray(object.jobs!) as Job[];
        object.workSessions = WorkSession.parseFromArray(object.workSessions!) as WorkSession[];
        if (object.manager) {
            object.manager = User.parseFromObject(object.manager!) as User;
        }
        if (object.diversions) {
            object.diversions = Diversion.parseFromArray(object.diversions);
        }
        Object.assign(location, object);
        return location;
    }

    public id?: string;
    // identifier of the location for the user
    public projectId?: string;
    public name?: string;
    public customer?: Customer;
    public active!: boolean;
    public managerId?: string;
    public manager?: User;
    public tenantId?: string;
    public tenant?: Tenant;
    public trafficArea?: TrafficAreas;
    public workSessions?: WorkSession[] = [];

    // start and EndDate of the Location
    public startDate?: string;
    public endDate?: string;

    // the main site of the location
    public address?: Address;
    // the addresses of the diversions
    public diversions: Diversion[] = [];

    public createdAt!: Date;


    public emergencyPhone!: string;

    public jobs?: Job[] = [];

    public topics: Topics[] = [];

    public description?: string;

    public files?: DBFile[];

    constructor() {
        super();
        this.address = new Address();
        this.customer = new Customer();
        this.topics = getTopics(false);
    }

    /**
     * Return a swallow object copy
     */
    public copy(): Location {
        return Location.parseFromObject(this.parseToObject());
    }

    // RN-388 just a swallow copy ... maybe this is not enough...
    public parseToObject(): Partial<Location> {
        const tmp: any = {...this};
        tmp.address = tmp.address ? tmp.address.parseToObject() : null;
        tmp.topics = tmp.topics.slice();
        return tmp;
    }
}
