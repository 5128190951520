import {ActionTree, Module} from 'vuex';
import Tenant from '@/models/Tenant.ts';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import TenantRepository from '@/api/repositories/TenantRepository';

const tenantRepository: TenantRepository = RepositoryFactory.get('tenant');

enum tenantStoreState {
    TENANTS = 'tenants',
    ACTIVE_TENANT = 'activeTenant',
}

const store = {
    /**
     * All tenants in the database - filtered
     */
    [tenantStoreState.TENANTS]: [],
    /**
     * The tenant the user belongs to or the current tenant for admin
     */
    [tenantStoreState.ACTIVE_TENANT]: undefined,
};

export enum tenantStoreActions {
    LOAD_TENANTS_ACTION = 'loadTenantsAction',
    LOAD_TENANT_ORIGIN = 'loadTenantOrigin',
    LOAD_TENANT_ACTION = 'loadTenantAction',
    CREATE_TENANT_ACTION = 'createTenantAction',
    EDIT_TENANT_ACTION = 'editTenantAction',
    DELETE_TENANT_ACTION = 'deleteTenantAction',
}

const actions: ActionTree<any, any> = {
    /**
     * Loads all tenants that are available for current user
     */
    [tenantStoreActions.LOAD_TENANTS_ACTION]: async ({commit}) => {
        const tenantsRaw = await tenantRepository.loadTenants().then((value) => {
            return value.records;
        });
        const tenants = Tenant.parseFromArray(tenantsRaw);

        commit(tenantStoreMutations.STORE_TENANTS, tenants);
        return tenants;
    },
    [tenantStoreActions.LOAD_TENANT_ORIGIN]: async ({commit}, tenantId: string, skip?: number, limit?: number) => {
        const tenantsRaw = await tenantRepository.loadTenantVc(tenantId, { skip, limit }).then((value) => value.records);
        return Tenant.parseFromArray(tenantsRaw);
    },
    [tenantStoreActions.LOAD_TENANT_ACTION]: async ({commit, state}, tenantId: string) => {
        const tenantRaw = await tenantRepository.loadTenant(tenantId);
        const tenant = Tenant.parseFromObject(tenantRaw);
        commit(tenantStoreMutations.ACTIVE_TENANT, tenant);
        return tenant;
    },
    [tenantStoreActions.CREATE_TENANT_ACTION]: async ({commit}, tenant: Tenant) => {
        const rawTenant = await tenantRepository.create(tenant);
        const createdTenant = Tenant.parseFromObject(rawTenant);
        commit(tenantStoreMutations.STORE_TENANT, createdTenant);
        return createdTenant;
    },
    [tenantStoreActions.EDIT_TENANT_ACTION]: async ({commit, state}, tenant: Tenant) => {
        const tenantCopy = tenant.parseToObject();

        const rawTenant = await tenantRepository.edit(tenantCopy);
        const editedTenant = Tenant.parseFromObject(rawTenant);
        commit(tenantStoreMutations.STORE_TENANT, editedTenant);

        // Also update the activeTenant (for example for the navigationBar!!
        if (state[tenantStoreState.ACTIVE_TENANT] && state[tenantStoreState.ACTIVE_TENANT].id === editedTenant.id) {
            commit(tenantStoreMutations.ACTIVE_TENANT, editedTenant);
        }

        return editedTenant;
    },
    [tenantStoreActions.DELETE_TENANT_ACTION]: async ({commit, state}, tenant: Tenant) => {
        const tenantRaw = await tenantRepository.delete(tenant).then((value) => {
            return value.records;
        });
        const deletedTenant = Tenant.parseFromObject(tenantRaw);
        commit(tenantStoreMutations.REMOVE_TENANT, deletedTenant);
        return deletedTenant;
    },
};

export enum tenantStoreMutations {

    STORE_TENANTS = 'storeTenants',
    STORE_TENANT = 'storeTenant',
    ACTIVE_TENANT = 'activeTenant',
    RESET = 'reset',
    REMOVE_TENANT = 'removeTenant',

}

const mutations = {
    [tenantStoreMutations.STORE_TENANTS]: (state: any, tenants: Tenant[]) => state[tenantStoreState.TENANTS] = tenants,
    [tenantStoreMutations.STORE_TENANT]: (state: any, tenant: Tenant) => {
        const index = state[tenantStoreState.TENANTS].findIndex((item: Tenant) => item.id === tenant.id);
        // replace or add tenant object
        if (index >= 0) {
            state[tenantStoreState.TENANTS].splice(index, 1, tenant);
        } else {
            state[tenantStoreState.TENANTS].push(tenant);
        }
    },
    [tenantStoreMutations.ACTIVE_TENANT]: (state: any, tenant: Tenant) => state[tenantStoreState.ACTIVE_TENANT] = tenant,
    [tenantStoreMutations.RESET]: (state: any) => {
        state[tenantStoreState.TENANTS] = [];
        state[tenantStoreState.ACTIVE_TENANT] = undefined;
    },
    [tenantStoreMutations.REMOVE_TENANT]: (state: any, tenant: Tenant) => {
        const index = state[tenantStoreState.TENANTS].findIndex((item: Tenant) => item.id === tenant.id);
        state[tenantStoreState.TENANTS].splice(index, 1);
    },
};

export enum tenantStoreGetter {
    TENANTS = 'tenants',
    ACTIVE_TENANT = 'activeTenant',
}

const getters = {
    [tenantStoreGetter.TENANTS]: (state: any) => state[tenantStoreState.TENANTS],
    [tenantStoreGetter.ACTIVE_TENANT]: (state: any) => state[tenantStoreState.ACTIVE_TENANT],
};

const tenantStore: Module<any, any> = {
    state: store,
    actions,
    mutations,
    getters,
};

export default tenantStore;
