import Parseable from '@/misc/Parseable';
import SubTopic from '@/models/SubTopic';
import {Topics} from '@/misc/Enums/Topics';
import {SubTopics} from '@/misc/Enums/SubTopics';

export default class Topic extends Parseable {

    public static parseFromObject(object: Partial<Topic>): Topic {
        const topic = new Topic();
        if (object.subTopics) {
            object.subTopics = SubTopic.parseFromArray(object.subTopics) as SubTopic[];
        }
        Object.assign(topic, object);
        return topic;
    }
    public id?: string;
    public name?: Topics;

    /*
     the Condition of the whole Topic.
     0 means everything all right.
     x>0 means there were Problems which were resolved on the tour.
     x<0 means there are unresolved problems
     */
    public condition?: number;
    public subTopics!: SubTopic[];



    public parseToObject(): Partial<Topic> {
        return {...this};
    }



    public copy(): Topic {
        return Topic.parseFromObject(this.parseToObject());
    }
}
