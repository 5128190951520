export default abstract class Parseable {
    public static parseFromObject(object: any): Parseable {
        // static abstract methods are not allowed in ts :(
        throw new Error('Overwrite me!!');
    }

    public static parseFromArray(array: any[]): Parseable[] {
        if (!Array.isArray(array)) {
            return [];
        }
        return array.map((obj) => this.parseFromObject(obj));
    }

    public abstract parseToObject(): any;

    /**
     * Creates a copy based on the parsedFromObject and parsedToObject functions.
     */
    public copy(): Parseable {
        // RN-395 improve this copy mechanism, maybe it is to general…
         return (this.constructor as any).parseFromObject(this.parseToObject());
    }

    /**
     * https://stackoverflow.com/a/8831937/2164158
     * @returns {number}
     */
    public hashCode(): number {
        const str = JSON.stringify(this.parseToObject());
        let hash = 0;
        if (str.length === 0) {
            return hash;
        }
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            // tslint:disable:no-bitwise
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash;
    }
}
