import {ActionTree, Module} from 'vuex';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import Customer from '@/models/Customer';
import CustomerRepository from '@/api/repositories/CustomerRepository';
import ColorMapperObject from '@/types/ColorMapperObject';

const customerRepository: CustomerRepository = RepositoryFactory.get('customer');

enum customerStoreState {
  CUSTOMERS = 'customers',
  CUSTOMER = 'customer',
}

const store = {
  /**
   * all customers from the active tenant
   */
  [customerStoreState.CUSTOMERS]: [],
  /**
   * One customer populated with more infos
   */
  [customerStoreState.CUSTOMER]: undefined,
};

export enum customerStoreActions {
  CANCEL_REQUEST_ACTION = 'cancelRequestsAction',
  LOAD_CUSTOMERS_ACTION = 'loadCustomersAction',
  LOAD_CUSTOMER_ACTION = 'loadCustomerAction',
  LOAD_CUSTOMER_ORIGIN = 'loadCustomerOrigin',
  CREATE_CUSTOMER_ACTION = 'createCustomerAction',
  DELETE_CUSTOMER_ACTION = 'deleteCustomerAction',
  EDIT_CUSTOMER_ACTION = 'editCustomerAction',
  LOAD_COLOR = 'loadColor',
}

const actions: ActionTree<any, any> = {
  [customerStoreActions.CANCEL_REQUEST_ACTION]: async () => {
    customerRepository.cancelRequests();
  },
  [customerStoreActions.LOAD_CUSTOMERS_ACTION]: async ({commit}, payload: { tenantId: string, store: boolean }):
    Promise<Customer[]> => {
    const customersRaw = await customerRepository.loadCustomers(payload.tenantId);
    const customers = Customer.parseFromArray(customersRaw) as Customer[];
    if (payload.store) {
      commit(customerStoreMutations.STORE_CUSTOMERS, customers);
    }
    return customers;
  },
  [customerStoreActions.LOAD_CUSTOMER_ACTION]:
    async ({commit}, payload: { customerId: string, store: boolean }) => {
      const customerRaw = await customerRepository.loadCustomer(payload.customerId);
      const customer = Customer.parseFromObject(customerRaw);
      if (payload.store) {
        commit(customerStoreMutations.STORE_CUSTOMER, customer);
      }
      return customer;
    },
  [customerStoreActions.LOAD_CUSTOMER_ORIGIN]:
    async ({commit}, customerId: string, skip?: number, limit?: number) => {
      const customersRaw = await customerRepository.loadCustomerVc(customerId, skip, limit);
      return Customer.parseFromArray(customersRaw);
    },
  [customerStoreActions.CREATE_CUSTOMER_ACTION]: async ({commit}, customer) => {
    const customerRaw = await customerRepository.create(customer);
    const createdCustomer = Customer.parseFromObject(customerRaw);
    commit(customerStoreMutations.ADD_CUSTOMER, createdCustomer);
    return createdCustomer;
  },
  [customerStoreActions.DELETE_CUSTOMER_ACTION]: async ({commit}, customer) => {
    const customerRaw = await customerRepository.delete(customer);
    const deletedCustomer = Customer.parseFromObject(customerRaw);
    commit(customerStoreMutations.REMOVE_CUSTOMER, deletedCustomer);
    return deletedCustomer;
  },
  [customerStoreActions.EDIT_CUSTOMER_ACTION]: async ({commit}, customer: Customer) => {
    // use copy and delete associated values
    const customerCopy = customer.parseToObject();
    const customerRaw = await customerRepository.edit(customerCopy);
    const editedCustomer = Customer.parseFromObject(customerRaw);
    commit(customerStoreMutations.UPDATE_CUSTOMER, editedCustomer);
    return editedCustomer;
  },
  [customerStoreActions.LOAD_COLOR]: async ({commit}, timeScheduleId: string): Promise<ColorMapperObject> => {
    return await customerRepository.loadColor(timeScheduleId);
  },
};

export enum customerStoreMutations {
  STORE_CUSTOMER = 'storeLocation',
  STORE_CUSTOMERS = 'storeLocation',
  ADD_CUSTOMER = 'storeLocation',
  REMOVE_CUSTOMER = 'storeLocation',
  UPDATE_CUSTOMER = 'updateCustomer',
}

const mutations = {
  [customerStoreMutations.STORE_CUSTOMER]: (state: any, customer: Customer) =>
    state[customerStoreState.CUSTOMER] = customer,
  [customerStoreMutations.STORE_CUSTOMERS]: (state: any, customers: Customer[]) =>
    state[customerStoreState.CUSTOMERS] = customers,
  [customerStoreMutations.ADD_CUSTOMER]: (state: any, customer: Customer) =>
    state[customerStoreState.CUSTOMERS].push(customer),
  [customerStoreMutations.REMOVE_CUSTOMER]: (state: any, customer: Customer) => {
    const index = state[customerStoreState.CUSTOMERS].findIndex((item: Customer) => item.id === customer.id);
    state[customerStoreState.CUSTOMERS].splice(index, 1);
  },
  [customerStoreMutations.UPDATE_CUSTOMER]: (state: any, customer: Customer) => {
    const index = state[customerStoreState.CUSTOMERS].findIndex((item: Customer) => item.id === customer.id);
    state[customerStoreState.CUSTOMERS].splice(index, 1, customer);
  },
};

export enum customerStoreGetter {
  CUSTOMERS = 'customers',
  CUSTOMER = 'customer',
}

const getters = {
  [customerStoreGetter.CUSTOMERS]: (state: any) => state[customerStoreState.CUSTOMERS],
  [customerStoreGetter.CUSTOMER]: (state: any) => state[customerStoreState.CUSTOMER],
};

const customerStore: Module<any, any> = {
  state: store,
  actions,
  mutations,
  getters,
};

export default customerStore;
