import EntityBaseRepository from '@/api/repositories/EntityBaseRepository';
import User from '@/models/User';
import TimeSchedule from '@/models/TimeSchedule';
import {FindAllResponse} from '@/interfaces/FindAllResponse';
import Location from '@/models/Location';
import UserRole from '@/models/user-attributes/UserRole';

export default class UserRepository extends EntityBaseRepository {

    /**
     * Loads only users who are no admin!
     */
    public loadUsers(tenantId: string, relations: string[] = []): Promise<any> {
        let relation = '';

        if (relations.length > 0) {
            relation = '&relations=';
            relation.concat(relations.join('&relations='));
        }
        return this.axiosClient(`user?tenantId=${tenantId}${relation}`);
    }

    public loadUserVc(id: string, options: { skip?: number, limit?: number }): Promise<FindAllResponse<Location>> {
        const queryOptions: string[] = [];
        Object.keys(options).forEach((key: string) => {
            if (options[key]) {
                queryOptions.push(`${key}=${options[key]}`);
            }
        });

        return this.axiosClient.get(`user/archive/${id}?${queryOptions.join('&')}`);
    }

    public loadUserByEmail(email: string): Promise<any> {
        return this.axiosClient(`user?email=${email}`);
    }

    public loadSingleUser(userId: string): Promise<any> {
        return this.axiosClient(`user/${userId}`);
    }

    public deleteUser(user: Partial<User>): Promise<any> {
        return this.axiosClient.delete(`user/${user.id}`);
    }

    public createUser(user: User): Promise<any> {
        return this.axiosClient.post('user', user);
    }

    public updateUser(user: Partial<User>): Promise<any> {
        return this.axiosClient.patch(`user/${user.id}`, user);
    }

    public setUserStatus(payload: { id: string, active: boolean, preview?: boolean, force?: boolean }): Promise<Partial<User>> {
        let queryParams;
        if (payload.preview || payload.force) {
            if (payload.preview && payload.force || payload.preview) { // prefer preview over force if both  params are set
                queryParams = '?preview';
            } else {
                queryParams = '?force';
            }
        }
        return this.axiosClient.patch(`user/${payload.id}${queryParams}`, {active: payload.active});
    }
    public loadUserRoles(tenantId: string, relations: string[] = []): Promise<any> {
        let relation = '';
        if (relations.length > 0) {
            relation = '&relations=';
            relation.concat(relations.join('&relations='));
        }
        return this.axiosClient.get(`role?tenantId=${tenantId}${relation}&sort=name%2BASC`);
    }
    public deleteUserRole(roleId: string): Promise<any> {
        return this.axiosClient.delete(`role/${roleId}`);
    }

    public updateUserRole(role: UserRole): Promise<UserRole> {
        return this.axiosClient.patch(`role/${role.id}`, role);
    }

    public createNewUserRole(role: UserRole): Promise<any> {
        return this.axiosClient.post('role', role);
    }
    public loadUserAttributes(): Promise<any> {
        return this.axiosClient.get('userAttributes');
    }

    public changePassword(payload: { user: User, passwordOld: string, password: string }): Promise<void> {
        const params = {passwordOld: payload.passwordOld, password: payload.password};
        return this.axiosClient.patch(`user/${payload.user.id}`, params);
    }

    public resendInvitation(userId: string): Promise<void> {
        return this.axiosClient.post(`user/${userId}/resendInvite`);
    }

    public calculateWorkHoursLeft(currentUsers: User[], currenttimeSchedule: TimeSchedule): Promise<any> {
        return this.axiosClient.post('user/calculateWorkHoursLeft', {
            users: currentUsers, timeSchedule: currenttimeSchedule,
            timeScheduleID: currenttimeSchedule.id ? currenttimeSchedule.id : 'new',
        });
    }
    public async testEnvironment(): Promise<{ token: string, refreshToken: string, user: User }> {
        return this.axiosClient.get('testEnvironment/start');
    }

    public downloadTimesheet(id: string, date: string): Promise<any> {
        return this.axiosClient.get(`user/${id}/timesheet/${date}`, {
            responseType: 'blob',
        });
    }
}
