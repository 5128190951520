import Parseable from '@/misc/Parseable';
import User from '@/models/User';
import Location from '@/models/Location';
import Note from '@/models/Note.ts';
import Job from '@/models/Job';

interface RenderData {
    conditions: {
        positives: number;
        negatives: number;
    };
    comments: number;
    images: number;
}
import Topic from '@/models/Topic';
import GeoPosition from '@/models/GeoPosition';
import {TimeDuration} from '@/helper/TimeDuration';
import TimeSchedule from '@/models/TimeSchedule';
import moment from 'moment';

export default class WorkSession extends Parseable {

    public static parseFromObject(object: Partial<WorkSession>): WorkSession {
        const workSession = new WorkSession();
        if (object.topics) {
            object.topics = Topic.parseFromArray(object.topics) as Topic[];
        }
        if (object.job) {
            object.job = Job.parseFromObject(object.job!);
        }
        object.user = User.parseFromObject(object.user!);
        object.location = Location.parseFromObject(object.location!);
        Object.assign(workSession, object);
        return workSession;
    }

    public id!: string;

    /**
     * the start- and endTime according to the driver
     */
    public startTime!: TimeDuration;
    public endTime!: TimeDuration;
    public startDate!: string;
    // result of startTime and endTime
    public duration!: number;

    /**
     * the start- and endTime according to the Server
     */
    public createdAt!: string;
    public durationSetAt?: string;
    // results out of createdAt and durationSetAt
    public trackedDuration!: number;

    public job!: Job;
    public jobId!: string;

    public topics?: Topic[];

    public notes: Note[] = [];

    public cancelled?: boolean;
    public driverPath?: GeoPosition;
    public user!: User;
    public userId!: string;

    public location!: Location;
    public locationId!: string;

    public parseToObject() {
        return {...this};
    }

    public copy(): WorkSession {
        return WorkSession.parseFromObject(this.parseToObject());
    }

    public renderData: RenderData = {
        conditions: {
            positives: 0,
            negatives: 0,
        },
        comments: 0,
        images: 0,
    };

    public getRenderData(): void {
        this.startTime = {
            hour: moment(this.startDate).hours(),
            minute: moment(this.startDate).minute(),
        };

        this.topics?.forEach((topic) => {
            if (topic.condition) {
                if (topic.condition > 0) {
                    this.renderData.conditions.positives += 1;
                } else if (topic.condition < 0) {
                    this.renderData.conditions.negatives += 1;
                }
            }
            topic.subTopics.forEach((subTopic) => {
                if (subTopic.photos !== null) {
                    this.renderData.images += subTopic.photos ? subTopic.photos.length : 0;
                }
                if (subTopic.comment) {
                    this.renderData.comments += subTopic.comment ? 1 : 0;
                }
            });
        });

    }
}
