import Parseable from '@/misc/Parseable';
import User from '@/models/User';

export default class Note extends Parseable {

    public static parseFromObject(object: Partial<Note>): Note {
        const note = new Note();
        if (object.writer) {
            object.writer = User.parseFromObject(object.writer);
        }
        Object.assign(note, object);
        return note;
    }

    public id?: string;
    public text?: string;
    public writer?: User;
    public writerId?: string;
    public createdAt?: string;
    public workSessionId!: string;

    public parseToObject(): Partial<Note> {
        return {...this};
    }

    public copy(): Note {
        return Note.parseFromObject(this.parseToObject());
    }
}
