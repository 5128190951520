import Parseable from '@/misc/Parseable';
import GeoPosition from '@/models/GeoPosition';

export default class Address extends Parseable {

    /**
     * Turns a plain object to a class object
     * @param object
     */
    public static parseFromObject(object: Partial<Address>): Address {
        const address = new Address();
        if (object.geoPosition) {
            object.geoPosition = GeoPosition.parseFromObject(object.geoPosition);
        }

        Object.assign(address, object);
        return address;
    }

    public id?: string;
    public street?: string;
    public houseNo?: string;
    public postalCode?: string;
    public addition?: string;
    public city?: string;
    public country?: string;

    public geoPosition?: GeoPosition;
    /**
     * If true, the geo position could not be obtained
     */
    public geocodePending?: boolean;
    /**
     * Returns the class object as plain object representation
     */
    public parseToObject(): Partial<Address> {
        return {...this};
    }

    // TODO SRC2-125
    constructor() {
        super();
        this.geoPosition = new GeoPosition();
        this.country = 'Germany';
    }

    /**
     * Simple Copy function to copy Address object.
     * Overrides Parseable copy function
     */
    public copy(): Address {
        return Address.parseFromObject(this.parseToObject());
    }
}
