import EntityBaseRepository from '@/api/repositories/EntityBaseRepository';
import {CancelToken, CancelTokenSource} from 'axios';
import JobOccurrence from '@/models/JobOccurrence';
import WorkSession from '@/models/WorkSession';
import {FindAllResponse} from '@/interfaces/FindAllResponse';
import Note from '@/models/Note';
import Image from '@/models/Image';

export default class JobOccurrenceRepository extends EntityBaseRepository {

    private cancelTokenSourceOption: { cancelToken: CancelToken | undefined } = {cancelToken: undefined};
    private cancelTokenSource!: CancelTokenSource;

    constructor() {
        super();
        this.cancelTokenSource = this.cancelTokenFactory.source();
        this.cancelTokenSourceOption.cancelToken = this.cancelTokenSource.token;
    }

    public loadJobOccurrences(tenantId: string, queryData: string): Promise<FindAllResponse<JobOccurrence>> {
        return this.axiosClient.get(`/job/jobSummary/${tenantId}?${queryData}`, this.cancelTokenSourceOption);
    }

    public loadWorkSession(workSessionId: string): Promise<WorkSession> {
        return this.axiosClient.get(`/workSession/${workSessionId}`, this.cancelTokenSourceOption);
    }

    public loadWorkSessionNotes(workSessionId: string): Promise<FindAllResponse<Note>> {
        return this.axiosClient.get(`/note?workSessionId=${workSessionId}&relations=writer&sort=createdAt%2BASC`);
    }
    public createWorkSessionNote(note: Note): Promise<Note> {
        return this.axiosClient.post(`note`, note);
    }
    public editWorkSessionNote(note: Note): Promise<Note> {
        return this.axiosClient.patch(`/note/${note.id}`, note);
    }
    public deleteWorkSessionNote(noteId: string) {
        return this.axiosClient.delete(`/note/${noteId}`);
    }

    public getWorkSessions(queryString: string, relations: string[]): Promise<FindAllResponse<WorkSession>> {
        let relation = '';

        if (relations.length > 0) {
            relation = '&relations=' + relations.join('&relations=');
        }
        return this.axiosClient.get(`/workSession${queryString}${relation}`, this.cancelTokenSourceOption);
    }

    public cancelRequests() {
        this.cancelTokenSource.cancel('cancelled by ui');
        this.cancelTokenSource = this.cancelTokenFactory.source();
        this.cancelTokenSourceOption.cancelToken = this.cancelTokenSource.token;
    }

    public downloadPDF(id: string) {
        return this.axiosClient.get(`/workSession/${id}/download`, { responseType: 'blob' });
    }
    public getWorkSessionImages(workSessionId: string): Promise<any> {
        return this.axiosClient.get(`/workSession/${workSessionId}/images`);
    }
}
