import EntityBaseRepository from '@/api/repositories/EntityBaseRepository';
import {PaymentMethod} from '@/interfaces/PaymentMethod';
import * as stripe from 'stripe';
import {FindAllResponse} from '@/interfaces/FindAllResponse';
import DBFile from '@/interfaces/DBFile';
import Tenant from '@/models/Tenant';

export default class PaymentRepository extends EntityBaseRepository {
    private baseUrl: string = 'tenant';


    public createPaymentMethod(method: PaymentMethod): Promise<stripe.Stripe.PaymentMethod> {
        return this.axiosClient.post(`${this.baseUrl}/stripe/paymentMethod`, method);
    }

    public getPaymentMethods(customerId: string) {
        return this.axiosClient.get(`${this.baseUrl}/stripe/paymentMethod`);
    }

    public getDefaultPaymentMethod(): Promise<string> {
        return this.axiosClient.get(`${this.baseUrl}/stripe/defaultPaymentMethod`);
    }

    public changeDefaultPaymentMethod(methodId: string): Promise<string> {
        return this.axiosClient.patch(`${this.baseUrl}/stripe/defaultPaymentMethod`, {value: methodId});
    }

    public deletePaymentMethod(methodId: string): Promise<stripe.Stripe.PaymentMethod> {
        return this.axiosClient.delete(`${this.baseUrl}/stripe/paymentMethod/${methodId}`);
    }

    public getCurrentMonthCosts(tenantId: string): Promise<number> {
        return this.axiosClient.get(`${this.baseUrl}/${tenantId}/monthInvoice`);
    }

    public getPaymentIntents(tenantId?: string): Promise<stripe.Stripe.ApiList<stripe.Stripe.PaymentIntent>> {
        return this.axiosClient.get(`${this.baseUrl}/stripe/paymentIntents/${tenantId}`);
    }

    public getInvoices(tenantId: string): Promise<FindAllResponse<DBFile>> {
        return this.axiosClient.get(`${this.baseUrl}/${tenantId}/invoice`);
    }

    public downloadInvoice(tenantId: string, invoiceId: string): Promise<Blob> {
        return this.axiosClient.get(`${this.baseUrl}/${tenantId}/invoice/${invoiceId}`, {responseType: 'blob'});
    }

    public resolveIntent(tenant: Partial<Tenant>): Promise<Tenant> {
        return this.axiosClient.patch(`${this.baseUrl}/${tenant.id}`, tenant);
    }
}
