export const WeekDays = [{
    name: 'Monday',
    index: 1,
}, {
    name: 'Tuesday',
    index: 2,
}, {
    name: 'Wednesday',
    index: 3,
}, {
    name: 'Thursday',
    index: 4,
}, {
    name: 'Friday',
    index: 5,
}, {
    name: 'Saturday',
    index: 6,
}, {
    name: 'Sunday',
    index: 0,
}];

export function getAllWeekDaysByNumber(): number[] {
    return WeekDays.map((day) => day.index);
}
export function getAllWeekDays(): any[] {
    return WeekDays.map((day) => day.name);
}
export function getWeekDayByNumber(dayNum: number): string {
    const obj = WeekDays.find((day) => day.index === dayNum);
    return obj ? obj.name : '';
}
export function getNumberByWeekDay(dayName: string): number {
    const obj = WeekDays.find((day) => day.name === dayName);
    return obj ? obj.index : NaN;
}
