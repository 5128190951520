import AuthRepository from '@/api/repositories/AuthRepository';
import TenantRepository from '@/api/repositories/TenantRepository';
import CustomerRepository from '@/api/repositories/CustomerRepository';
import UserRepository from '@/api/repositories/UserRepository';
import JobOccurrenceRepository from '@/api/repositories/JobOccurrenceRepository';
import JobRepository from '@/api/repositories/JobRepository';
import AnalyticsRepository from '@/api/repositories/AnalyticsRepository';
import ImportRepository from '@/api/repositories/ImportRepository';
import ExportRepository from '@/api/repositories/ExportRepository';
import LocationRepository from '@/api/repositories/LocationRepository';
import RoleRepository from '@/api/repositories/RoleRepository';
import PaymentRepository from '@/api/repositories/PaymentRepository';

const repositories: any = {
    auth: {repository: AuthRepository, instance: null},
    tenant: {repository: TenantRepository, instance: null},
    customer: {repository: CustomerRepository, instance: null},
    location: {repository: LocationRepository, instance: null},
    user: {repository: UserRepository, instance: null},
    job: {repository: JobRepository, instance: null},
    jobOccurrence: {repository: JobOccurrenceRepository, instance: null},
    analytics: {repository: AnalyticsRepository, instance: null},
    import: {repository: ImportRepository, instance: null},
    export: {repository: ExportRepository, instance: null},
    role: {repository: RoleRepository, instance: null},
    payment: {repository: PaymentRepository, instance: null},
};

/**
 *  Factory to create repositories. All created repositories are singleton instances.
 */
export const RepositoryFactory = {
    get: (name: string) => {
        if (repositories[name].instance) {
            return repositories[name].instance;
        } else {
            return new repositories[name].repository();
        }
    },
};
