import EntityBaseRepository from '@/api/repositories/EntityBaseRepository';
import Location from '@/models/Location';
import {CancelToken, CancelTokenSource} from 'axios';
import {FindAllResponse} from '@/interfaces/FindAllResponse';
import DBFile from '@/interfaces/DBFile';

export default class LocationRepository extends EntityBaseRepository {

    private cancelTokenSourceOption: { cancelToken: CancelToken | undefined } = {cancelToken: undefined};
    private cancelTokenSource!: CancelTokenSource;

    constructor() {
        super();
        this.cancelTokenSource = this.cancelTokenFactory.source();
        this.cancelTokenSourceOption.cancelToken = this.cancelTokenSource.token;
    }


    public create(location: Location): Promise<Location> {
        return this.axiosClient.post(`location`, location, this.cancelTokenSourceOption);
    }

    public edit(location: Partial<Location>): Promise<Location> {
        return this.axiosClient.patch(`location/${location.id}`, location, this.cancelTokenSourceOption);
    }

    public delete(locationId: string): Promise<void> {
        return this.axiosClient.delete(`location/${locationId}`, this.cancelTokenSourceOption);
    }

    public loadLocation(locationId: string): Promise<Location> {
        return this.axiosClient.get(`location/${locationId}`, this.cancelTokenSourceOption);
    }

    public loadLocationVc(id: string, options: { skip?: number, limit?: number }): Promise<FindAllResponse<Location>> {
        const queryOptions: string[] = [];
        Object.keys(options).forEach((key: string) => {
            if (options[key]) {
                queryOptions.push(`${key}=${options[key]}`);
            }
        });

        return this.axiosClient.get(`location/archive/${id}?${queryOptions.join('&')}`);
    }

    public loadLocations(tenantId: string, relations: string[] = []): Promise<FindAllResponse<Location>> {
        let relation = '';

        if (relations.length > 0) {
            relation = '&relations=';
            relation = relation.concat(relations.join('&relations='));
        }
        return this.axiosClient.get(`location?tenant=${tenantId}${relation}`, this.cancelTokenSourceOption);
    }

    public loadCustomersLocations(customerId: string, relations?: string[]): Promise<any> {
        let query = '';
        if (relations) {
            query = '&relations=' + relations.join(',');
        }
        return this.axiosClient.get(`location?customer=${customerId}${query}`, this.cancelTokenSourceOption);
    }

    public deleteFile(locationId: string, fileId: string): Promise<void> {
        return this.axiosClient.delete(`location/${locationId}/file/${fileId}`);
    }

    public createFiles(locationId: string, files: FormData): Promise<FindAllResponse<DBFile>> {
        return this.axiosClient.post(`location/${locationId}/uploadFile`, files);
    }

    public deleteDiversion(diversionId: string): Promise<void> {
        return this.axiosClient.delete(`location/diversion/${diversionId}`);
    }

    public downloadFile(locationId: string, fileId: string) {
        return this.axiosClient.get(`location/${locationId}/download/${fileId}`, { responseType: 'blob' });
    }


    /**
     * Cancels all request of this repository and applies a new cancel token
     */
    public cancelRequests() {
        this.cancelTokenSource.cancel('cancelled by ui');
        this.cancelTokenSource = this.cancelTokenFactory.source();
        this.cancelTokenSourceOption.cancelToken = this.cancelTokenSource.token;
    }
}
